export interface Plan {
    id: number;
    planName: string;
    basePrice: number;
    duration: number;
    isActive: boolean;
    creationDate: string;
    hasAppointmentReminder: boolean;
    hasCustomNotifications: boolean;
    maxActiveCalendars: number;
    maxActiveAppointmentTypes: number;
  }

  export function createDefaultPlan(): Plan {
    let defaultValue: Plan = {
        id: 0,
        planName: '',
        basePrice: 0,
        duration: 0,
        isActive: false,
        creationDate: '',
        hasAppointmentReminder: false,
        hasCustomNotifications: false,
        maxActiveCalendars: 0,
        maxActiveAppointmentTypes: 0,
      };
  
    return defaultValue;
  }