import axios from "axios";
import store from "@/data/store.js";
import router from "../routes/router";
import { getSafe } from "@/util/helpers";

const contentTypeJsonHeader = { "Content-Type": "application/json" };
const ApiClient = axios.create({
  baseURL: process.env.VUE_APP_APIBASEURL,
  withCredentials: false,
  headers: {
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, DELETE, PUT, OPTIONS",
    "Access-Control-Allow-Headers":
      "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With"
    //'oid': process.env.VUE_APP_OID
  }
});

ApiClient.interceptors.request.use(
  async request => {
    await store.dispatch("showLoadingState");
    let token = getSafe(() => store.state.currentUser.token);
    if (token !== "undefined") {
      // request.headers = {
      //   'Authorization': `Bearer ${token}`
      // }
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  },
  async error => {
    await store.dispatch("hideLoadingState");
    Promise.reject(error);
  }
);

function goToLogin() {
  if (router.currentRoute.name !== "login") router.push({ name: "login" });
}

function hasToken() {
  let token = getSafe(() => store.state.currentUser.token);
  if (token !== "undefined") {
    return true;
  } else return false;
}

async function tryRefreshToken() {
  let tokenRefreshed = false;
  try {
    tokenRefreshed = await store.dispatch("refreshToken", {
      token: store.state.currentUser.token,
      refreshToken: store.state.currentUser.refreshToken
    });
  } catch (error) {
    //goToLogin(router);
    console.error(error);
  }
  return tokenRefreshed;
}



let isRefreshing = false;
let failedQueue = [];


const processQueue = error => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  });
  failedQueue = [];
};

ApiClient.interceptors.response.use(
  async res => {
    await store.dispatch("hideLoadingState");
    return res;
  },
  async error => {
    await store.dispatch("hideLoadingState");
    const originalRequest = error.config;
    // If 401 and I'm not processing a queue
    if (
      error.response !== undefined &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      
      if (hasToken() && isRefreshing) {        
        let queuePromise = new Promise(function(resolve, reject) {
            failedQueue.push({ resolve, reject });
            
          });
          await queuePromise;
          originalRequest.headers.Authorization = `Bearer ${store.state.currentUser.token}`;
          return axios(originalRequest);
         
      }
      if (hasToken()) {
        
        originalRequest._retry = true; // mark request a retry
        isRefreshing = true; // set the refreshing var to true
        //Se ho il token e non sto già refreshando,
        //provo a refreshare
        let tokenRefreshed = await tryRefreshToken();
        isRefreshing = false;
        if (tokenRefreshed === true) {
          //Se sono riuscito a refreshare rifaccio la chiamata
          
          processQueue();
          return ApiClient(originalRequest);
        } else {
          
          goToLogin();
          Promise.reject("Unable to refresh. Need to login.");
        }
      }
    
    }
    return Promise.reject(error);
  }
);


export default ApiClient;
