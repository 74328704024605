import { AppointmentLocationType } from "@/data/Models/Appointment/AppointmentLocationType";
import { ApplicationUserAppointmentType } from "@/data/Models/Appointment/ApplicationUserAppointmentType";

export enum AppTypeDiscriminiator {
  Blank="",
  PersonalAppointmentType="PersonalAppointmentType",
  TeamAppointmentType="TeamAppointmentType",
  RoundRobinAppointmentType="RoundRobinAppointmentType"
}

export interface BaseAppType {
  id: number;
  name: string;
  description: string;
  durationMinutes: number;
  timeSliceSize: number;
  useDurationAsTimeSliceSize:boolean;
  appUrl: string;
  isEnabled: boolean;
  customNotificationMessage: string;
  applicationUserAppointmentType:ApplicationUserAppointmentType[];
  locationTypes: AppointmentLocationType[];
  isReminderEnabled: boolean;
  reminderOffsetHours: number;
  isVisibleInMainBookingPage: boolean;
  discriminator: AppTypeDiscriminiator;
}

export function createDefaultBaseAppType(): BaseAppType {
    let defaultValue: BaseAppType = {
            id: 0,
            name: '',
            description: '',
            durationMinutes: 30,
            timeSliceSize:15,
            useDurationAsTimeSliceSize:true,
            appUrl: '',
            isEnabled: false,
            customNotificationMessage: '',
            applicationUserAppointmentType: [],
            locationTypes: [],
            isReminderEnabled: false,
            reminderOffsetHours: 24,
            isVisibleInMainBookingPage: true,
            discriminator: AppTypeDiscriminiator.Blank
          };
  


    return defaultValue;
  }

