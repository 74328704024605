<template>
    <b-alert :show="globalAlertBarInfo.show" :variant="globalAlertBarInfo.variant" class=" alertBar-fixed">
      <div v-html="globalAlertBarInfo.message" style="font-weight: bold;">
        {{ globalAlertBarInfo.message }}
      </div>
    </b-alert>
</template>
<script>
export default {
  name: "alert-bar",
  computed: {
    globalAlertBarInfo() {
      return this.$store.state.globalAlertBarInfo;
    },
  },
};
</script>
<style scoped>
.alertBar-fixed{
    position:fixed; 
    top: 0px; 
    left: 0px; 
    width: 100%;
    z-index:1039; 
    border-radius:0px
}
.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}
</style>