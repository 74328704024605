import { Plan,createDefaultPlan } from "@/data/Models/Plan/Plan";

export interface Subscription {
  id: number;
  planId: number;
  applicationUserId: number;
  creationDate: string;
  startDate: string;
  endDate: string;
  status: string;
  paymentStatus: string;
  isAutoRenewActive: boolean;
  isCurrentSubscription: boolean;
  plan: Plan;
}

export function createDefaultSubscription(): Subscription {
  let defaultValue: Subscription = {
    id: 0,
    planId: 0,
    applicationUserId: 0,
    creationDate: '',
    startDate: '',
    endDate: '',
    status: '',
    paymentStatus: '',
    isAutoRenewActive: false,
    isCurrentSubscription: false,
    plan: createDefaultPlan()
  };

  return defaultValue;
}
