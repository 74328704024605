































































































import {
  ApplicationUserAppointmentType,
  createDefaultAUAT
} from "@/data/Models/Appointment/ApplicationUserAppointmentType";
import { getTeamUserAvailabilitySets } from "@/data/availabilitySet";
import { TeamAvailabilitySet } from "@/data/Models/Availability/TeamAvailabilitySet";
import { cloneDeep } from "lodash";

import Vue, { PropType } from "vue";
import { AvailabilitySet } from "@/data/Models/Availability/AvailabilitySet";
export default Vue.extend({
  name: "team-availabilities-details",
  data() {
    return {
      localAuat: createDefaultAUAT()
      //usersAvSet: [] as undefined | TeamAvailabilitySet[]
    };
  },
  props: {
    auat: {
      type: Object as PropType<ApplicationUserAppointmentType> | undefined
    },
    teamId: {
      type: String
    },
    mode: {
      type: String //Can be insert or edit
    },
    teamAvSets: {
      type: (Array as PropType<Array<TeamAvailabilitySet>>) || undefined
    },
    alreadyAddedOrganizatorIds: {
      type: Array as PropType<Array<number>>,
      default: [] as number[]
    }
  },
  async mounted() {
    this.localAuat = cloneDeep(this.auat);
    await this.loadUserAvSet();
  },
  computed: {
    show(): Boolean {
      return this.auat != undefined;
    },
    // addableTeamMembers(): Array<{ userId: number; mail: string }> {
    //   let result = Array<{ userId: number; mail: string }>();
    //   if (this.teamAvSets !== undefined) {
    //     result = this.teamAvSets
    //       .filter(x => !this.alreadyAddedOrganizatorIds.includes(x.userId))
    //       .map(x => {
    //         return {
    //           userId: x.userId,
    //           mail: x.userMail
    //         };
    //       });
    //   }
    //   return result;
    // },
    addableTeamMembers(): Array<{ userId: number; mail: string }> {
      let result = Array<{ userId: number; mail: string }>();
      if (this.teamAvSets !== undefined) {
        result = this.teamMembers.filter(
          x => !this.alreadyAddedOrganizatorIds.includes(x.userId)
        );
      }
      return result;
    },
    teamMembers(): Array<{ userId: number; mail: string }> {
      let result = Array<{ userId: number; mail: string }>();
      if (this.teamAvSets !== undefined) {
        result = this.teamAvSets
          //.filter(x => !this.alreadyAddedOrganizatorIds.includes(x.userId))
          .map(x => {
            return {
              userId: x.userId,
              mail: x.userMail
            };
          });
      }
      return result;
    },
    selectedUserAvSets(): Array<{ setId: number; setName: string }> {
      //filtro in modo da avere solo i set dello user selezionato
      let result = Array<{ setId: number; setName: string }>();
      if (this.teamAvSets !== undefined && this.teamAvSets.length > 0) {
        let avSetsOfUser = this.teamAvSets.filter(
          x => x.userId == this.localAuat.applicationUsersId
        );
        if (avSetsOfUser.length > 0) {
          result = avSetsOfUser[0].availabilitySets.map(x => {
            return {
              setId: x.setId,
              setName: x.setName
            };
          });
        }
        // result = this.usersAvSet
        //   .filter(x => x.userId == this.localAuat.applicationUsersId)[0]
        //   .availabilitySets.map(x => {
        //     return {
        //       setId: x.setId,
        //       setName: x.setName
        //     };
        //   });
      }

      return result;
    }
   
  },
  methods: {
    getOrganizatorMail(userId: number) {
      let teamMember = this.teamMembers.find(x => x.userId == userId);
      return teamMember ? teamMember.mail : "";
    },

    async loadUserAvSet() {
      //this.usersAvSet = await getTeamUserAvailabilitySets(this.teamId);
      //
    },
    async emitEdit() {
      let validator = this.$refs.myValidator as any;
      let valid = await validator.validate();
      if (valid) this.$emit("edited", this.localAuat);
    },
    emitCancelled() {
      this.$emit("canceled");
    },
    toSelectObject(
      sourceArray: Array<any>,
      valueProp: string,
      textProp: string
    ): any[] {
      return sourceArray.map(obj => ({
        text: obj[textProp],
        value: obj[valueProp]
      }));
    }
  }
});
