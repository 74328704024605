import { extend } from "vee-validate";
import { parse } from "date-fns";
extend("stringHourMustBeGreatherThan", 
{
  validate(value, { fieldName, inputComparingValue }) {
    let baseValue = parse(value, "HH:mm", new Date());
    let comparingValue = parse(inputComparingValue, "HH:mm", new Date());

    if (baseValue > comparingValue) return true;
    else return "Il campo deve essere maggiore di " + fieldName;
  },
  params: ["fieldName", "inputComparingValue"]
});

//Regola per validare sommariamente gli url validi per il link all'apputnamento ( nomeUrlPrenotazione e nomeUrlAppuntamento )
//Che in particolare devono consentire la mail visto che di default nomeUrlPrenotazione contiente la mail dell'utente.
extend("validSchedeoUrlName", {
  validate(value) {
    let regEx = /^[a-zA-Z0-9@.\-+_]{3,}$/;
    let isValid = regEx.test(value);
    if (isValid === true) return true;
    else
      return `Il campo contiene caratteri non validi ( può contere lettere, numeri e questi @ - _ +) oppure è troppo corto ( minimo 3 caratteri )`;
    //${field}
  }
});

extend("stringDateMustBeEqualOrGreatherThan", {
  validate(value, { fieldName, inputComparingValue }) {
    let baseValue = value;
    let comparingValue = inputComparingValue;

    if (baseValue > comparingValue || baseValue == comparingValue) return true;
    else return "Il campo deve essere maggiore o uguale a " + fieldName;
  },
  params: ["fieldName", "inputComparingValue"]
});

// min_value: parse(availability.startHour,'HH:mm', new Date())
