import apiClient from '../util/ApiClient';

/**
 *
 * @returns  {Array<CalendarAccountDTO>}
 */
export const getAccountsAndCalendars = async function () {

    //return apiClient.get('Calendar/GetAccountsAndCalendars');
    let result = await apiClient.get('Calendar/GetAccountsAndCalendars');
    return result.data
}

export const saveCalendarAccounts = async function (calendarAccounts) {

    return apiClient.post('Calendar/SaveCalendarAccounts', calendarAccounts);
}

export const addGoogleCalendarAccount = async function (authCode, redirectUrl) {

    return apiClient.post('Calendar/AddGoogleCalendarAccount',null, { params: { authCode: authCode, redirectUrl: redirectUrl } });
}

export const addO365CalendarAccount = async function (authCode, redirectUrl) {

  return apiClient.post('Calendar/AddO365CalendarAccount',null, { params: { authCode: authCode, redirectUrl: redirectUrl } });
}


export const deleteCalendarAccount = async function (id) {

    return apiClient.delete('Calendar/DeleteCalendarAccount', { params: { id } });
}
