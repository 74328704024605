import Vue from "vue";
import Vuex from "vuex";
import * as account from "../data/account";
import * as appointment from "@/data/appointmentService";
import * as calendarAccount from "@/data/calendarAccount";

Vue.use(Vuex);

const state = () => ({
  // token: localStorage.getItem('access_token') || null,
  // refreshToken: localStorage.getItem('refresh_token') || null,
  // currentUserId: localStorage.getItem('CurrentUserId') || null,
  //appointmentTypes: [],
  globalAlertInfo: {},
  globalAlertBarInfo: {},
  AccountsAndCalendars: [],
  currentUser: {
    userId: parseInt(localStorage.getItem("CurrentUserId")) || null,
    token: localStorage.getItem("access_token") || null,
    refreshToken: localStorage.getItem("refresh_token") || null,
    userUrl: localStorage.getItem("userUrl") || "",
    userName: localStorage.getItem("userName") || null,
    registrationDate: localStorage.getItem("registrationDate") || null
  },
  isLoading: false,
  activeRequests: 0
});

const mutations = {
  setAccountsAndCalendars(state, AccountsAndCalendars) {
    state.AccountsAndCalendars = AccountsAndCalendars;
  },
  setAppointmentTypes(state, appointmentTypes) {
    state.appointmentTypes = appointmentTypes;
  },

  setGlobalAlertInfo(state, info) {
    state.globalAlertInfo = info;
  },
  setGlobalAlertBarInfo(state, info) {
    state.globalAlertBarInfo = info;
  },
  setCurrentUser(state, info) {
    state.currentUser.userId = info.userId;
    state.currentUser.token = info.token;
    state.currentUser.refreshToken = info.refreshToken;
    state.currentUser.userUrl = info.userUrl;
    state.currentUser.userName = info.userName;
    state.currentUser.registrationDate = info.registrationDate;

    localStorage.setItem("access_token", info.token || "");
    localStorage.setItem("refresh_token", info.refreshToken || "");
    localStorage.setItem("CurrentUserId", info.userId || "");
    localStorage.setItem("userUrl", info.userUrl || "");
    localStorage.setItem("userName", info.userName || "");
    localStorage.setItem("registrationDate", info.registrationDate || "");
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  incrementActiveRequest(state) {
    state.activeRequests = state.activeRequests + 1;
  },
  decrementActiveRequest(state) {
    state.activeRequests = state.activeRequests - 1;
  }
};

const actions = {
  async createToken({ commit }, { email, password }) {
    return account.createToken(email, password).then(response => {
      commit("setCurrentUser", response.data);

      // localStorage.setItem('access_token', response.data.token);
      // localStorage.setItem('refresh_token', response.data.refreshToken);
      // localStorage.setItem('CurrentUserId', response.data.userId);
      // localStorage.setItem('userUrl', response.data.userUrl);
      // localStorage.setItem('userName', response.data.userName);
    });
  },

  updateGlobalAlertInfo({ commit }, info) {
    commit("setGlobalAlertInfo", info);
  },

  updateGlobalAlertBarInfo({ commit }, info) {
    commit("setGlobalAlertBarInfo", info);
  },
  async getAccountsAndCalendars({ commit }) {
    var result = await calendarAccount.getAccountsAndCalendars();
    commit("setAccountsAndCalendars", result);
  },
  async refreshToken({ commit }, data) {
    let tokenRefreshed = false;
    var response = await account.refreshToken(data.token, data.refreshToken);
    if (response.status === 201) {
      // commit('setToken', response.data.token);
      // commit('setRefreshToken', response.data.refreshToken)
      // commit('setCurrentUserId', response.data.userId);

      commit("setCurrentUser", response.data);

      // localStorage.setItem('access_token', response.data.token);
      // localStorage.setItem('refresh_token', response.data.refreshToken);
      // localStorage.setItem('CurrentUserId', response.data.userId);
      // localStorage.setItem('userUrl', response.data.userUrl);
      // localStorage.setItem('userName', response.data.userName);
      tokenRefreshed = true;
    }

    return tokenRefreshed;
  },
  clearLoginData({ commit }) {
    localStorage.setItem("access_token", "");
    localStorage.setItem("refresh_token", "");
    localStorage.setItem("CurrentUserId", "");
    localStorage.setItem("userUrl", "");
    localStorage.setItem("userName", "");

    commit("setCurrentUser", {});
  },
  updateCurrentUser({ commit }, user) {
    commit("setCurrentUser", user);
  },
  showLoadingState({ commit,state }) {
    commit("incrementActiveRequest");
    // console.log("inc ar:" + state.activeRequests);
    commit("setLoading", true);
  },
  hideLoadingState({ commit, state }) {
    commit("decrementActiveRequest");
    // console.log("dec ar:" + state.activeRequests);
    if (state.activeRequests <= 0) commit("setLoading", false);
  }
};

const getters = {
  loggedIn(state) {
    if (state.currentUser.token) return true;
    else return false;
  },
  currentUser(state) {
    return state.currentUser;
  },
  isLoading(state) {
    return state.isLoading;
  }
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
});
