import { AppointmentLocationType } from "@/data/Models/Appointment/AppointmentLocationType";
import { ApplicationUserAppointmentType } from "@/data/Models/Appointment/ApplicationUserAppointmentType";
import {
  BaseAppType,
  createDefaultBaseAppType
} from "@/data/Models/Appointment/BaseAppType";

export interface PersonalAppType extends BaseAppType {
  // id: number;
  // name: string;
  // description: string;
  // durationMinutes: number;
  // appUrl: string;
  // isEnabled: boolean;
  // customNotificationMessage: string;
  //applicationUserAppointmentType: ApplicationUserAppointmentType[];
  // locationTypes: AppointmentLocationType[];
  // isReminderEnabled: boolean;
  // reminderOffsetHours: number;
  // isVisibleInMainBookingPage: boolean;
  // discriminator: string;
}

export function createDefaultPersonalAppType(): PersonalAppType {
  //al momento sono uguali, quindi non ha senso crearne un altro
  return createDefaultBaseAppType();
  // let baseValue = createDefaultBaseAppType();
  
  // let defaultValue: PersonalAppType = {
  //   ...baseValue,
  //   applicationUserAppointmentType: []
  // };

  // return defaultValue;
}

// export function importFromBaseAppType(src:BaseAppType, dest:PersonalAppType)
// {
//   id: 0,
//   name: '',
//   description: '',
//   durationMinutes: 0,
//   appUrl: '',
//   isEnabled: true,
//   customNotificationMessage: '',
//   applicationUserAppointmentType: [],
//   locationTypes: [],
//   isReminderEnabled: false,
//   reminderOffsetHours: 24,
//   isVisibleInMainBookingPage: true,
//   discriminator: ''
// }
