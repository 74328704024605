import {
  BaseAppType,
  createDefaultBaseAppType
} from "@/data/Models/Appointment/BaseAppType";

export interface TeamBaseAppType extends BaseAppType {
  teamOwnerId: string;
}

export function createDefaultTeamBaseAppType(): TeamBaseAppType {

    //Creo un'istanza della classe base e 
    //gli aggiungo il campo che manca
  let baseValue = createDefaultBaseAppType();

  let defaultValue: TeamBaseAppType = {
    ...baseValue,
    teamOwnerId: ""
  };

  return defaultValue;
}
