import apiClient from "../util/ApiClient";
import "@/data/Models/GetCustomerCardsResponse";
//import "@/data/Models/ChangeSubscriptionRequest";
import {ChangeSubscriptionRequest} from "@/data/Models/ChangeSubscriptionRequest";
import "@/data/Models/InitSubscriptionChangeResponse";
import "@/data/Models/Subscription.js";
import "@/data/Models/Plan";

/**
 * @param {ChangeSubscriptionRequest} changeSubscriptionRequest
 */
export const changeSubscription = async function(changeSubscriptionRequest:ChangeSubscriptionRequest) {
  let result = await apiClient.post(
    "Subscription/ChangeSubscription",
    changeSubscriptionRequest
  );
  return result.data;
};

export const finalizeSubscriptionPayment = async function(paymentIntentId) {
  let result = await apiClient.post(
    "Subscription/FinalizeSubscriptionPayment",
    { paymentIntentId: paymentIntentId }
  );
  return result.data;
};

/**
 * @returns {Array<GetCustomerCardsResponse>}
 */
export const getCustomerCards = async function() {
  let result = await apiClient.get("Subscription/GetCustomerCards");
  return result.data;
};

export const deleteCustomerPaymentMethod = async function(paymentMethodId) {

  let result = await apiClient.post(
    "Subscription/DeleteCustomerPaymentMethod",
    paymentMethodId,
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );

  return result.data;
};

/**
 *
 * @param {number} planId
 * @returns {Promise<InitSubscriptionChangeResponse>}
 */
export const initSubscriptionChange = async function(planId) {
  
  let result = await apiClient.post("Subscription/InitSubscriptionChange", {
    planId: planId
  });
  return result.data;
};
/**
 *
 * @returns {Promise<Subscription>}
 */
export const getCurrentSubscription = async function():Promise<Subscription> {
  let result = await apiClient.get("Subscription/GetCurrentSubscription");
  return result.data;
};

export const changeCurrentSubAutoRenewState = async function(newState) {
  let result = await apiClient.post(
    "Subscription/ChangeCurrentSubAutoRenewState",
    newState.toString(),
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
  return result.data;
};

