






















































































































































































































































































































































































import TimeTable from "@/components/TimeTable/TimeTable.vue";
import Wyswyg from "@/components/Wyswyg.vue";
import SelectPicker from "@/components/SelectPicker.vue";
import AddressLocationModal from "@/components/AppointmentTypeDetails/AddressLocationModal.vue";
import PhoneLocationModal from "@/components/AppointmentTypeDetails/PhoneLocationModal.vue";
import AppointmentLocationList from "@/components/AppointmentTypeDetails/AppointmentLocationList.vue";

import globalMixins from "@/util/mixins/MyGlobalMixins.vue";

//import * as appointment from "@/data/appointmentService";
import { discriminatorToItalianType } from "@/data/appointmentService";
import * as availablitySet from "@/data/availabilitySet";
import router from "@/routes/router";
import store from "@/data/store.js";
import { copyToClipboard } from "@/util/helpers";
import { getCurrentSubscription } from "@/data/subscriptionService";

import { cloneDeep } from "lodash";
import { capitalizeAllFirstLetters } from "@/util/stringHelpers.js";

//DTO
import "@/data/Models/AppointmentLocationTypeDTO";

import Vue, { VueConstructor, PropType } from "vue";
import { PersonalAppType } from "@/data/Models/Appointment/PersonalAppType";
import {
  BaseAppType,
  createDefaultBaseAppType
} from "@/data/Models/Appointment/BaseAppType";
import { AppointmentLocationType } from "@/data/Models/Appointment/AppointmentLocationType";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
// import "@ @/assets/custom_css/generic.css";
import "@/assets/custom_css/custom-vue-select.css";

interface TimeSliceSlotOption {
  id: string;
  minutes: number;
  name: string;
}

//export default Vue
export default (Vue as VueConstructor<
  Vue & InstanceType<typeof globalMixins>
>).extend({
  mixins: [globalMixins],
  name: "base-app-type-details-component",
  components: {
    TimeTable,
    Wyswyg,
    SelectPicker,
    AddressLocationModal,
    AppointmentLocationList,
    vSelect
  },
  data() {
    return {
      //selectedAppType: createDefaultBaseAppType(),
      locationTypes: [
        { value: null, text: "Se vuoi, aggiungi il luogo dell'appuntamento" },
        { value: "AddressLocationType", text: "Indirizzo" },
        { value: "PhoneLocationType", text: "Telefono" },
        {
          value: "GoogleMeetLocationType",
          text: "Video Chiamata - Google Meet"
        }
      ],
      /**
       * @type {AppointmentLocationTypeDTO}
       */
      selectedLocationType: {} as any,
      inputSelectValue: null,
      componentMapping: {
        AddressLocationType: AddressLocationModal,
        PhoneLocationType: PhoneLocationModal
      },
      discriminatorToItalianType: discriminatorToItalianType,
      timeSliceSlotOptions: [] as Array<TimeSliceSlotOption> //[5, 10, 15, 20, 30, 60],
    };
  },
  async mounted() {
    this.timeSliceSlotOptions = this.generateTimeSliceSlotOptions();
    //await this.loadInterface();
  },

  props: {
    id: {
      type: Number,
      default: 0
    },
    baseAppType: {
      type: Object as PropType<BaseAppType>
    },
    currentSubscription: {
      type: Object as PropType<Subscription>
    },
    name: {
      type: String
    },
    durationMinutes: {
      type: Number
    },
    timeSliceSize: {
      type: Number
    },
    useDurationAsTimeSliceSize: {
      type: Boolean
    },
    appUrl: {
      type: String
    },
    isVisibleInMainBookingPage: {
      type: Boolean
    },
    isReminderEnabled: {
      type: Boolean
    },
    reminderOffsetHours: {
      type: Number
    },
    customNotificationMessage: {
      type: String
    },
    locations: {
      type: Array as PropType<Array<AppointmentLocationType>>
    }
  },
  // watch: {
  //   async id() {
  //     await this.loadInterface();
  //   }
  //   // baseAppType: {
  //   //   async handler(newValue, oldValue) {

  //   //     await this.loadInterface();
  //   //   },
  //   //   deep: true
  //   // }
  // },
  computed: {
    userUrl() {
      return store.state.currentUser.userUrl;
    }
  },

  methods: {
    async showHelpTimeSliceSize() {
      await this.$showHtmlModalMessage(
        "Info",
        `
        Con il campo "Frequenza delle Disponibilità" puoi specificare la frequenza in cui saranno disponibili i tuoi slot.<br><br>
        Ad esempio:
        <br>
        <ul>
          <li>se scegli 15 gli slot saranno simili a 09.00 / 09.15 / 09.30...<br/><br/></li>
          <li>se scegli 30 gli slot saranno simili a 09.00 / 09.30 / 10.00...<br/><br/></li>
          <li>se scegli 60 gli slot saranno simili a 09.00 / 10.00 / 11.00...<br/><br/></li>
        </ul>`
      );
    },

    generateTimeSliceSlotOptions(): Array<TimeSliceSlotOption> {

      let arr = [5, 10, 15, 20, 30, 60];
      // arr.forEach((item) => {
      //   item.id = this.generateUniqueId();
      // });
      // return arr;
      //return arr;
      const result = arr.map(value => {
        return {
          minutes: value,
          name: String(value),
          id: this.generateUniqueId()
        } as TimeSliceSlotOption;
      });
      // result.splice(0, 0, {
      //   id: this.generateUniqueId(),
      //   minutes: -1,
      //   name: "Usa la durata dell'appuntamento"
      // } as TimeSliceSlotOption);
      return result;
    },

    // createOption(newTimeSliceSize) {
    //   debugger;
    //   let newOption = {
    //     id: this.generateUniqueId(),
    //     minutes: newTimeSliceSize,
    //     name: String(newTimeSliceSize)
    //   } as TimeSliceSlotOption;
    //   return newOption;
    // },

    handleTimeSliceSizeInput(slotOption: TimeSliceSlotOption) {
      // console.log(slotOption);
      this.$emit("update:timeSliceSize", Number(slotOption.minutes));
    },
    handleUseDurationAsTimeSliceSizeChange(value: Boolean) {
      this.$emit("update:useDurationAsTimeSliceSize", value);
    },
    setAppUrl() {
      if (this.appUrl === "" && this.name !== "") {
        //Se appUrl non è già popolato lo valorizzo sulla base del nome dell'appType

        let str = capitalizeAllFirstLetters(this.name);

        //rimuovo tutto ciò che non è permesso e taglio al max a X characters
        str = str.replace(/[^a-zA-Z0-9@.\-+_]/g, "");
        str = str.substring(0, 20);
        this.$emit("update:appUrl", str);
      }
    },
    async onSelect_LocationChanged(selectedOption) {
      switch (selectedOption) {
        case "GoogleMeetLocationType":
          //In questo caso non ho dettagli da gestire, quindi non mostro alcuna modale.
          //creo e passo al locationEdited
          let newLocation = {
            appointmentTypeId: this.id,
            locationType: selectedOption
          };
          await this.locationEdited(newLocation);
          break;
        default:
          //Negli altri casi ho dei dettagli da gestire, quindi apro la relativa modale
          this.selectedLocationType = {
            appointmentTypeId: this.id,
            locationType: selectedOption
          };
          break;
      }

      //blanko la selezione
      this.inputSelectValue = null;
    },
    async locationEdited(location) {
      //Trovo l'indice della location modificata
      let index = this.locations.findIndex(i => i.id === location.id);
      if (index > -1) {
        //In questo caso modifico
        let result = this.locations.map(obj => {
          if (obj.id === location.id) {
            return cloneDeep(location);
          } else return obj;
        });

        this.$emit("update:locations", result);
      } else {
        //In questo caso aggiungo
        let result = cloneDeep(this.locations) as any[];
        result.push(location);
        this.$emit("update:locations", result);
      }
      this.selectedLocationType = undefined;
      await this.saveAppType();
    },

    async onRemoveItem(itemId) {
      let sourceArray = cloneDeep(this.locations) as AppointmentLocationType[];
      let objToRemove = sourceArray.find(i => i.id === itemId);
      if (objToRemove !== undefined) {
        sourceArray.splice(sourceArray.indexOf(objToRemove), 1);
      }
      this.$emit("update:locations", sourceArray);
      await this.saveAppType();
    },

    onEditItem(itemId) {
      this.selectedLocationType = this.locations.find(i => i.id === itemId);
    },
    async deleteAppointmentType() {
      let confirmed = await this.$showConfirmDelete(
        `Stai per cancellare il tipo di appuntamento '${this.name}'.<br/>Sicuro di voler procedere?`
      );
      if (confirmed) {
        this.$emit("delete-requested", this.id);
      }
    },

    // async onSaveButtonClicked() {
    //   await this.saveAppType();
    // },

    async showHelpReminders() {
      await this.$showHtmlModalMessage(
        "Invio dei Promemoria",
        `
        Se attivi questa opzione i tuoi clienti riceveranno una mail di promemoria per l'appuntamento che hanno con te.

        `
      );
    },

    async showHelpAppUrl() {
      await this.$showHtmlModalMessage(
        "Info",
        `
        Inserisci un nome breve che identifica questo tipo di appuntamento ( verrà inserito nel tuo link di prenotazione ).

        `
      );
    },
    async showHelpLocation() {
      await this.$showHtmlModalMessage(
        "Dove",
        `
        Qui puoi specificare dove vuoi che si svolga l'appuntamento ( non è obbligatorio ).<br/>
        Il luogo sarà aggiunto alla mail di conferma appuntamento sia a te che a chi prenota.<br/><br/>
        Inserendo:
        <ul>
          <li>
          Indirizzo - invieremo l'indirizzo che scrivi
          </li>
          <li>
          Google Meet - invieremo automaticamente il link della videoconferenza.
          </li>
          <li>
          Telefono - invieremo il numero di telefono che scrivi.
          </li>

        </ul>
        <br/>
        Se inserisci più "Luoghi" chi prenota potrà scegliere tra le opzioni disponibili.


        `
      );
    },
    async showHelpIsActive() {
      await this.$showHtmlModalMessage(
        "Attivo",
        `<ul>
          <li>
        Abilita questa opzione se vuoi che questo tipo di appuntamento sia attivo e prenotabile.<br/><br/>
        </li>
        <li>
        Disabilita questa opzione se vuoi che questo tipo di appuntamento non sia prenotabile.<br/>
        Se qualcuno proverà ad accedere al link di questo appuntamento riceverà un messaggio che lo avvisa che al momento non possibile prenotare questo tipo di appuntamento.<br/>
        </li>
        </ul>
        `
      );
    },
    async showHelpIsVisibleInMainBookingPage() {
      await this.$showHtmlModalMessage(
        "Visualizza in Pagina Prenotazione Generale",
        `<ul>
          <li>
        Abilita questa opzione se vuoi che questo tipo di appuntamento sia visibile nella tua pagina di prenotazione principale.<br/><br/>
        </li>
        <li>
        Disabilita questa opzione se vuoi che questo tipo di appuntamento sia prenotabile solo dalle persone che hanno il link diretto alla pagina di prenotazione di questo appuntamento.<br/>
        </li>
        </ul>
        `
      );
    },
    async showHelpCustomNotificationMessage() {
      await this.$showHtmlModalMessage(
        "Info",
        `
        <strong>Se vuoi usare il messaggio di default, lascia questo campo vuoto.</strong><br/><br/>
        Il campo "Notifica personalizzata" ti permette di impostare il messaggio che verrà inviato alle persone quando prenderanno un appuntamento per questo tipo di appuntamento.<br/><br/>
        Ad esempio:
        <ul>
          <li>
          se sei un medico puoi scrivere un messaggio che ricorda di portare determinati documenti all'appuntamento.
          </li>
          <li>
          se sei un consulente puoi scrivere un messaggio che ricorda di preparare determinate informazioni prima dell'appuntamento
          </li>
          <li>
          ecc
          </li>
        </ul>
        <br/>
        Sostanzialmente puoi scrivere qualsiasi cosa ritieni importante comunicare all'altra persona in modo che l'appuntamento si svolga al meglio.


        `
      );
    },
    async showHelpWhyCustomNotificationMessageDisabled(title) {
      await this.$showHtmlModalMessage(
        title,
        `
        Il tuo abbonamento attuale non include questa funzionalità.<br/>
        Se ti interessa avere questa funzione passa a un abbonamento superiore.<br/><br/>
        Se hai bisogno di aiuto contattaci telefonicamente o via mail ai recapiti che trovi sul sito www.schedeo.it<br/>

        `
      );
    },
    onBeforeIsReminderEnabledToggle(e) {
      if (
        !this.currentSubscription.plan.hasAppointmentReminder &&
        e.target.checked === true
      ) {
        e.preventDefault();
        this.$emit("update:isReminderEnabled", Boolean(false));
        this.showHelpWhyCustomNotificationMessageDisabled(
          "Invio dei Promemoria"
        );
      }
    },
    async saveAppType() {
      let validator = this.$refs.myValidator as any;
      let valid = await validator.validate();

      if (valid) {
        this.$emit("save-requested");
      }
    }
  }
});
