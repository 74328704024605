var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"timeTableTimeDetailsMain"},[_c('div',{staticClass:"inputTimeContainer"},[_c('div',[_c('validation-provider',{attrs:{"rules":{ required: true },"name":"Dalle","vid":_vm.generateUniqueId()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{staticClass:"timeInput",attrs:{"options":_vm.hoursDataSource,"value":_vm.startHour},on:{"input":_vm.onStartHourChange}}),_vm._t("error",[(errors[0])?_c('div',{staticClass:"invalid-feedback timeInputError",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])]}}],null,true)})],1),_c('div',{staticStyle:{"padding-left":"3px","padding-right":"3px","padding-top":"8px"}},[_vm._v("-")]),_c('div',[_c('validation-provider',{attrs:{"rules":{
          required: true,
          stringHourMustBeGreatherThan: ['Dalle', _vm.startHour]
        },"name":"Alle","vid":_vm.generateUniqueId()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-select',{staticClass:"timeInput",attrs:{"options":_vm.hoursDataSource,"value":_vm.endHour},on:{"input":_vm.onEndHourChange}}),_vm._t("error",[(errors[0])?_c('div',{staticClass:"invalid-feedback timeInputError",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])]}}],null,true)})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }