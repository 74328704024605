/**
 *
 * @param {string} sentence
 * @returns {string}
 */
export const capitalizeAllFirstLetters = function(sentence) {
  const words = sentence.trim().split(" ");

  if (words.length>0 && words[0]=="") return "";

  return words
    .map(word => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(" ");
};
