import apiClient from "../util/ApiClient";
import {TeamAvailabilitySet} from "@/data/Models/Availability/TeamAvailabilitySet"
import { AvailabilitySet } from "./Models/Availability/AvailabilitySet";

export const getTeamUserAvailabilitySets = async function(teamId: string):Promise<TeamAvailabilitySet[]> {
  var result = await apiClient.get("Appointment/getTeamUserAvailabilitySets", {
    params: { teamId }
  });
  return result.data;
};

export const getAvailabilitySets = async function() {
  var result = await apiClient.get("Appointment/GetAvailabilitySets");
  return result;
};

export const GetAvailabilitySetByIdForUI = async function(id):Promise<AvailabilitySet> {
  let result = await apiClient.get("Appointment/GetAvailabilitySetByIdForUI", {
    params: { id }
  });
  return result.data;
};

// export const GetAvailabilitySetByIdForUI = async function(id) {
//   return apiClient.get("Appointment/GetAvailabilitySetByIdForUI", {
//     params: { id }
//   });
// };

export const saveAvailabilitySet = async function(availabilitySet) {
  return apiClient.post("Appointment/SaveAvailabilitySet", availabilitySet);
};

export const deleteAvailabilitySet = async function(id) {
  //return apiClient.delete('Appointment/DeleteAvailabilitySet', { data: { id: id } });
  return apiClient.delete("Appointment/DeleteAvailabilitySet", {
    params: { id }
  });
};

/**
 * @param {string} eventId
 * @returns {Promise<SingleAttendeeEventDTO>}
 *
 */
export const getAvailabilitySetOverrides = async function(setId, sDate, eDate) {
  let result = await apiClient.get("Appointment/GetAvailabilitySetOverrides", {
    params: { setId, sDate, eDate }
  });

  return result.data;
};

/**
 * @param {Array<DayOverrideDTO>} daysOverride
 */
export const saveOverrides = async function(daysOverride) {
  let result = await apiClient.post("Appointment/SaveOverride", daysOverride);
  return result.data;
};

export const deleteOverride = async function(setId, sDate, eDate) {
  return apiClient.delete("Appointment/DeleteOverride", {
    params: { setId, sDate, eDate }
  });
};
