<template>
  <!-- <label v-if="label" :class="labelClasses">
          {{label}}
        </label> -->
  <div>
    <div>
      <label v-if="label" :class="labelClasses">
        {{label}}
      </label>
    </div>
    <label class="custom-toggle">
      <input type="checkbox" v-bind="$attrs" :checked="value" @click="updateValue" />
      <!-- v-model="model" -->
      <span class="custom-toggle-slider rounded-circle"></span>
    </label>
  </div>
</template>
<script>
export default {
  name: "base-switch",
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      default: false,
      description: "Switch value"
    },
    labelClasses: {
      type: String,
      description: "Input label css classes",
      default: "form-control-label"
    },
    label: {
      type: String,
      description: "Input label (text before input)"
    }
  },
  computed: {
    // /**
    //  * @returns {boolean}
    //  */
    // model: {
    //   /**
    //    * @returns {boolean}
    //    */
    //   get() {
    //     return this.value;
    //   },
    //   /**
    //    * @param {boolean} value
    //    */
    //   // set(value) {
    //   //   this.$emit("input", value);
    //   //   this.$emit("check-changed", value);
    //   // }
    //   set(evt) {
    //     this.$emit("input", evt.target.checked);
    //     this.$emit("check-changed", evt);
    //   }
    // }
  },
  methods:{
    updateValue(evt) {
      //Il fire dell'updateValue, viene fatto al click perchè gli altri eventi ( checked e input ) non sono cancellable, 
      //quindi con quelli non è possibile prevenire l'attivazione o disattivazione dello switch (perchè non è possibile fare e.preventDefault)
      let checked = evt.target.checked;
      
      //Questo evento è usato dal v-model per aggiornare la proprietà
      this.$emit("input", checked);
      
      //Questo evento deve essere usato per eventuali necessità di hooking
      this.$emit("check-changed", evt);
    },
  }
};
</script>
<style></style>