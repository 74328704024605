<template>
  <div style="min-width:320px;">
    <cookie-bar
      text="Questo sito o gli strumenti terzi da questo utilizzati si avvalgono di cookie necessari al funzionamento ed utili alle finalità illustrate nella cookie policy. Se vuoi saperne di più o negare il consenso a tutti o ad alcuni cookie, consulta la cookie policy.
Cliccando sul bottone sottostante o proseguendo la navigazione in altra maniera, acconsenti all’uso dei cookie."
      :customLink="true"
      link="https://www.iubenda.com/privacy-policy/gdpr/58668908/cookie-policy"
      linkText="Cookie Policy"
      acceptText="Accetto"
      :currentPage="$route.fullPath"
    />
    <loading
      :active.sync="this.$store.getters.isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>

    <!-- <div v-if="this.$store.getters.isLoading" class="loadingBackdrop">spinner</div> -->
    <!-- <div class="loadingBackdrop">spinner</div> -->
    <router-view :key="$route.fullPath"></router-view>
    <!-- <b-modal id="my-modal">Hello From My Modal!</b-modal>
     -->
    <!-- <modal :show="globalAlertInfo.visible" v-on:close="hideGlobalAlert()"> </modal> -->
    <modal-alert> </modal-alert>
    <alert-bar></alert-bar>
  </div>
</template>

<script>
import ModalAlert from "./components/ModalAlert.vue";
import AlertBar from "./components/AlertBar.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import CookieBar from "@/components/CookieBar/CookieBar.vue";
import { getQueryStringValue } from "@/util/helpers";

export default {
  components: {
    ModalAlert,
    AlertBar,
    Loading,
    CookieBar
  },
  computed: {
    globalAlertInfo() {
      return this.$store.state.globalAlertInfo;
    },
    test() {
      return process.env.VUE_APP_APIBASEURL;
    },
    vueUtm() {
      return this.$utm;
    }
  },
  created: function() {
    //moment().tz.setDefault("UTC");
  },
  mounted: function() {
    this.setUtmParameters();
  },
  methods: {
    setUtmParameters() {
      this.$utm.utm_source = getQueryStringValue("utm_source");
      this.$utm.utm_campaign = getQueryStringValue("utm_campaign");
      this.$utm.utm_content = getQueryStringValue("utm_content");
      this.$utm.utm_campaignId = getQueryStringValue("utm_campaignid");
      this.$utm.utm_adGroupId = getQueryStringValue("utm_adgroupid");
      this.$utm.utm_keyword = getQueryStringValue("utm_keyword");
      this.$utm.utm_matchType = getQueryStringValue("utm_matchtype");
    }
  }

  // },
};
</script>
<style>
.clickable {
  cursor: pointer;
}
.loadingBackdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0.3 !important;
}
.modalHeader h5 {
  font-size: 1.3rem;
  border-bottom: 1px solid #2dce8b;
  width: 100%;
}

/**
* Vue Loading
*/
.vld-overlay.is-full-page {
  z-index: 2103 !important;
}
</style>
