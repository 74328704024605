<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot="links">
        <b-nav-item
          v-if="isNewUser() && this.$route.name != 'thank_you'"
          @click="showFirstThingsToDo"
        >
          <div class="text-left">
            <i class="fas fa-walking text-primary" style="min-width:2rem;font-size: .9375rem;line-height: 1.5rem;padding-left:3px;"></i>
            Le Prime cose da Fare
          </div>
        </b-nav-item>



        <!-- <b-nav-item
          v-if="isNewUser() && this.$route.name != 'thank_you'"
          class="first-thing-to-do text-center mx-auto"
          @click="showFirstThingsToDo"
        >
          <div class="text-center text-white">
            <i class="fas fa-arrow-alt-circle-right"></i>
            Le Prime cose da Fare
            <i class="fas fa-arrow-alt-circle-left"></i>
          </div>
        </b-nav-item> -->

        <sidebar-item
          :link="{
            //name: 'Tipi di Appuntamento',
            name: 'Pagine di Prenotazione',
            path: '/appointment_types',
            icon: 'fas fa-list text-primary'
            
          }"
          
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Appuntamenti Schedulati',
            path: '/appointments',
            icon: 'far fa-calendar-check text-primary'
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Gestione Disponibilità',
            path: '/availabilities',
            icon: 'fas fa-hourglass-half  text-primary'
          }"
        />
        <sidebar-item
          :link="{
            name: 'Teams',
            path: '/teams',
            icon: 'fas fa-users  text-primary'
          }"
        />

        <sidebar-item
          :link="{
            name: 'Impostazioni',
            path: '',
            icon: 'fas fa-cog text-primary'
          }"
        >
          <sidebar-item
            :link="{
              name: 'Profilo',
              path: '/profile',
              icon: 'fas fa-user-alt text-primary'
            }"
          />
          <sidebar-item
            :link="{
              name: 'Calendari Sincronizzati',
              path: '/calendars',
              icon: 'fas fa-calendar-alt text-primary'
            }"
          />
          <sidebar-item
            :link="{
              name: 'Logo',
              path: '/Logo',
              icon: 'fas fa-user-alt text-primary'
            }"
          />
          <sidebar-item
            :link="{
              name: 'Abbonamento',
              path: '/subscription',
              icon: 'fas fa-file-invoice text-primary'
            }"
          />
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Guida Rapida',
            path: '/getting_started',
            icon: 'fas fa-info-circle text-primary'
          }"
        />
      </template>
    </side-bar>
    <div class="main-content">
      <div class="d-none d-md-block">
        <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
      </div>
      <div class="bg-gradient-success" style="height:50px;">
        <!-- <notifications-viewer >
        <template v-slot:info="{notificationsQty,toggleShow}">
          <div class="text-right pt-1 pr-2">
          <div class="d-inline-block text-center">
            <div class="fas fa-bell navbar-icon dl-bell" @click="toggleShow" >
            </div>
          <div style="height:1px;"></div>
            <div class="dl-bell-counter" @click="toggleShow">{{notificationsQty}}</div>
          </div>
          </div>
        </template>
      </notifications-viewer> -->
      </div>

      <div class="" @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->

          <!-- <div class="pt-3 pt-md-5 pl-1 pr-1 pl-sm-4 pr-sm-4"> -->
          <div class="pt-3 pt-md-5 pl-4 pr-4">
            <div>
              <b-row>
                <b-col>
                  <h1 class="pageTitle">{{ $route.meta.shownName }}</h1>
                  <div class="pageSubtitle" v-html="$route.meta.subTitle"></div>
                </b-col>
              </b-row>
            </div>

            <router-view></router-view>
          </div>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

//import NotificationsViewer from "@/components/NotificationsViewer";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
//import DashboardContent from "./Content.vue";
import { FadeTransition } from "vue2-transitions";
//import differenceInHours from "date-fns/differenceInHours/index";
import { differenceInHours, isValid, parseISO } from "date-fns";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    //DashboardContent,
    FadeTransition
    //NotificationsViewer
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    addLandbot() {
      //faccio vedere il bot solo a quelli che si sono registrati da meno di x ore
      // let registrationDate = parseISO(
      //   this.$store.getters.currentUser.registrationDate
      // );
      // let now = new Date();
      // if (!isValid(registrationDate)) return;

      // let hourDifference = differenceInHours(now, registrationDate);
      // if (hourDifference >= 48) return;

      if (this.isNewUser()) {
        let Script = document.createElement("script");
        Script.setAttribute("src", "/js/landbotScript.js");
        document.head.appendChild(Script);
      }
    },
    isNewUser() {
      let registrationDate = parseISO(
        this.$store.getters.currentUser.registrationDate
      );
      let now = new Date();

      //se la data di registrazione non è valida non lo considero un nuovo utente
      if (!isValid(registrationDate)) return false;

      let hourDifference = differenceInHours(now, registrationDate);

      //restituisco se è un nuovo utente o meno
      return hourDifference <= 48;
    },
    async showFirstThingsToDo() {
      this.$sidebar.displaySidebar(false);
      await this.$showHtmlModalMessage(
        "Prime cose da fare",
        `
        <b>1. Configura i tuoi orari<br/></b>Vai in "Gestione Disponibilità" e configura i tuoi orari.<br/>
        <br/>
        <b>2. Configura la tua pagina di prenotazione<br/></b>Vai in "Pagine di Prenotazione" e modifica quella attiva. Cambiagli il nome, la durata e associagli la disponibilità che hai creato prima.<br/>
        <br/>
        <b>3. Usa la tua pagina di prenotazione generale!<br/></b>In "Pagine di Prenotazione" trovi la tua pagina di prenotazione generale,  inviala a chi deve prendere appuntamento con te!<br/>
        
        `
      );
    }
  },

  mounted() {
    this.initScrollbar();
    //this.addLandbot();
  }
};
</script>
<style scoped>

.first-thing-to-do {
  background-color: #ee8c10;
  /* background-color: #48c774; */
  border-radius: 8px;
  color: white;
}
.wrapper {
  max-width: 1250px;
  margin: 0 auto;
}

.pageTitle {
  margin-bottom: 0px;
  line-height: normal;
}
.pageSubtitle {
  font-size: 16px;
  color: #767575;
  font-weight: normal;
  margin-bottom: 1rem;
}
.dl-bell {
  font-size: 22px;
}
.dl-bell-counter {
  font-size: 10px;
  font-weight: 300;
  padding: 0px 8px;

  background-color: #ffc107;
  border-radius: 3px;
  padding-top: -3px;
}
/* landbot style */
.LandbotLivechat {
  z-index: 900 !important;
}
/* .dl-bell-counter {
  font-size: 10px;
  font-weight: 300;
  padding: 0px 8px;
  position: absolute;
  right: -1px;
  bottom: -16px;
  background-color: #ffc107;
  border-radius: 3px;
} */
</style>
