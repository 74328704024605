import { logError } from "../data/errorService.js";
import { getSafe } from "@/util/helpers";
import router from "../routes/router";
import { serializeError, deserializeError } from "serialize-error";
import { json } from "d3";

export const handleError = function(error, vm) {
  let errorStatus = getSafe(() => error.response.status);
  let safeErrorMessage = getSafe(() => error.response.data.safeMessage);

  ////////////////////////////////
  //Se è un messaggio utente visualizzo quello
  ////////////////////////////////
  if (safeErrorMessage) {
    //Errore safe
    vm.$showAlert(safeErrorMessage);
    return;
  }
  ////////////////////////////////
  //Se è un 401 mando in login
  ////////////////////////////////
  if (errorStatus === 401) {
    router.push({ name: "login" });
    return;
  }

if(errorStatus === 404){
  router.push({ name: "not-found" });
  return;
}


  ////////////////////////////////
  //Se non ho un errorStatus significa che non è un errore lato server,
  //tendenzialmente è un client, quindi loggo
  //Attenzione, in caso di chiamate a api esterne potrei non tracciare gli errori, più avanti rivedere la cosa.
  ////////////////////////////////
  if (errorStatus === undefined) {
    let enrichedError = {
      error: serializeError(error),
      component: getSafe(() => vm.$options.name),
      url: getSafe(() => router.currentRoute.path)
    };
    let jsonError = JSON.stringify(enrichedError);
    
    //faccio lo swallowing in modo che qualsiasi errore durante il log si chiuda qui senza rischiare di creare cicli infiniti
    try {
      logError(jsonError);
    } catch (ex) {}
  }

  ////////////////////////////////
  //Se arrivo qui è un errore generico, quindi mando un errore generico
  ////////////////////////////////

  //Errore applicativo non gestito
  vm.$showAlert(
    "Oooops... Si è verificato un errore.\r\n\r\nTi preghiamo di fare un altro tentativo. Se continui ad avere problemi contattaci per assistenza"
  );

  ////////////////////////////////
  //Se sono in sviluppo scrivo in console
  ////////////////////////////////
  if (process.env.NODE_ENV === "development") {
    console.error(error);
  }
};
