

export interface ApplicationUserAppointmentType {
    applicationUsersId: number;
    availabilitySetId: number;
}

export function createDefaultAUAT(): ApplicationUserAppointmentType {

  let defaultValue:ApplicationUserAppointmentType = {
    applicationUsersId:-1,
    availabilitySetId:-1

  }

 
  return defaultValue;
}