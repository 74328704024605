import { TeamAppType } from "@/data/Models/Appointment/TeamAppType";
import apiClient from "@/util/ApiClient";
import { AxiosResponse } from "axios";
import { TeamWithAppTypeSummary } from "@/data/Models/Appointment/TeamWithAppTypeSummary";

export const getTeamAppTypeById = async function(id:number):Promise<TeamAppType> {
    let result = await apiClient.get(`TeamAppType/GetTeamAppTypeById`, {
      params: { id }
    });
  
    return result.data;
  };

  export const saveTeamAppType = async function(appType:TeamAppType) {
    //inietto i tipi corretti per la deserializzazione polimorfica
  
    appType.locationTypes.forEach(appointmentLocationType => {
      var baseType = "SchedeoV2.CL.Data.DTO.AppointmentLocationTypes";
      appointmentLocationType.$type = `${baseType}.${appointmentLocationType.locationType}DTO`;
    });
    return apiClient.post("TeamAppType/SaveTeamAppType", appType);
  };

  export const getTeamAppointmentTypes = async function(): Promise<TeamWithAppTypeSummary[]> {
    let result = await apiClient.get("TeamAppType/GetTeamAppointmentTypes");
    return result.data;
  };

  export const deleteTeamAppType = async function(id:number) {
    return apiClient.delete("TeamAppType/DeleteTeamAppType", {
      params: { id }
    });
  };

  export const canCreateTeamAppType = async function(): Promise<Boolean> {
    let result = await apiClient.get("TeamAppType/CanCreateTeamAppType");
    return result.data;
  };
  

  