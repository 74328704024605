var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[(_vm.isNewUser() && this.$route.name != 'thank_you')?_c('b-nav-item',{on:{"click":_vm.showFirstThingsToDo}},[_c('div',{staticClass:"text-left"},[_c('i',{staticClass:"fas fa-walking text-primary",staticStyle:{"min-width":"2rem","font-size":".9375rem","line-height":"1.5rem","padding-left":"3px"}}),_vm._v(" Le Prime cose da Fare ")])]):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          //name: 'Tipi di Appuntamento',
          name: 'Pagine di Prenotazione',
          path: '/appointment_types',
          icon: 'fas fa-list text-primary'
          
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Appuntamenti Schedulati',
          path: '/appointments',
          icon: 'far fa-calendar-check text-primary'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Gestione Disponibilità',
          path: '/availabilities',
          icon: 'fas fa-hourglass-half  text-primary'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Teams',
          path: '/teams',
          icon: 'fas fa-users  text-primary'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Impostazioni',
          path: '',
          icon: 'fas fa-cog text-primary'
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Profilo',
            path: '/profile',
            icon: 'fas fa-user-alt text-primary'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Calendari Sincronizzati',
            path: '/calendars',
            icon: 'fas fa-calendar-alt text-primary'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Logo',
            path: '/Logo',
            icon: 'fas fa-user-alt text-primary'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Abbonamento',
            path: '/subscription',
            icon: 'fas fa-file-invoice text-primary'
          }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Guida Rapida',
          path: '/getting_started',
          icon: 'fas fa-info-circle text-primary'
        }}})],1)],2),_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"d-none d-md-block"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}})],1),_c('div',{staticClass:"bg-gradient-success",staticStyle:{"height":"50px"}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('div',{staticClass:"pt-3 pt-md-5 pl-4 pr-4"},[_c('div',[_c('b-row',[_c('b-col',[_c('h1',{staticClass:"pageTitle"},[_vm._v(_vm._s(_vm.$route.meta.shownName))]),_c('div',{staticClass:"pageSubtitle",domProps:{"innerHTML":_vm._s(_vm.$route.meta.subTitle)}})])],1)],1),_c('router-view')],1)])],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }