var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"myValidator"},[_c('b-card',{staticClass:"tabbed-page-card",attrs:{"no-body":""}},[_c('b-tabs',{attrs:{"card":""}},[_c('b-tab',{attrs:{"title":"Generale","active":""}},[_c('b-row',[_c('b-col',{staticStyle:{"max-width":"700px"}},[_c('b-form',[_c('div',{staticClass:"pl-lg-4"},[_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('div',{staticClass:"pb-2"},[_c('label',{staticClass:"form-control-label"},[_vm._v("Tipo Pagina di Prenotazione:")]),_c('label',{staticClass:"form-control-label",staticStyle:{"display":"block","font-weight":"normal"}},[_vm._v(" "+_vm._s(_vm.discriminatorToItalianType( _vm.baseAppType.discriminator ))+" ")])])]),_c('b-col',{attrs:{"lg":"12"}},[_c('base-input',{attrs:{"name":"Nome","rules":{ required: true },"type":"text","label":"Nome","placeholder":"Nome Tipo Appuntamento","value":_vm.name},on:{"input":function (value) {
                            this$1.$emit('update:name', value);
                          },"blur":_vm.setAppUrl}})],1),_c('b-col',{attrs:{"lg":"12"}},[_vm._t("availabilities")],2),_c('b-col',{attrs:{"lg":"12"}},[_c('base-input',{attrs:{"name":"Durata in Minuti","rules":{ required: true, min_value: 5 },"label":"Durata in Minuti","placeholder":"Durata in Minuti","value":_vm.durationMinutes},on:{"input":function (value) {
                            this$1.$emit(
                              'update:durationMinutes',
                              Number(value)
                            );
                          }}})],1),_c('b-col',{staticClass:"pb-4",attrs:{"lg":"12"}},[_c('label',{staticClass:"form-control-label"},[_vm._v(" Frequenza delle Disponibilità in Minuti ")]),_c('label',{staticClass:"form-control-label helpLabel",on:{"click":_vm.showHelpTimeSliceSize}},[_vm._v(" (Istruzioni) ")]),_c('b-form-checkbox',{staticClass:"pb-2",attrs:{"checked":_vm.useDurationAsTimeSliceSize},on:{"change":_vm.handleUseDurationAsTimeSliceSizeChange}},[_c('span',{staticClass:"text-muted",staticStyle:{"margin-left":"-20px"}},[_vm._v("Usa la durata dell'appuntamento ")])]),(!_vm.useDurationAsTimeSliceSize)?_c('validation-provider',{attrs:{"rules":{ required: true, min_value: 5 },"name":"Frequenza delle Disponibilità","vid":_vm.generateUniqueId()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-select',{staticClass:"form-control",attrs:{"options":_vm.timeSliceSlotOptions,"label":"name","value":_vm.timeSliceSize,"filterable":false,"searchable":false,"clearable":false},on:{"input":_vm.handleTimeSliceSizeInput}}),_vm._t("error",[(errors[0])?_c('div',{staticClass:"invalid-feedback",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])]}}],null,true)}):_vm._e()],1),_c('b-col',{attrs:{"lg":"12"}},[_c('base-input',{attrs:{"name":"Nome Url Appuntamento","rules":{
                          required: true,
                          validSchedeoUrlName: true
                        },"label":"Nome Url Appuntamento","placeholder":"Nome Url Univoco","value":_vm.appUrl,"showHelpLabel":true},on:{"input":function (value) {
                            this$1.$emit('update:appUrl', value);
                          },"onHelpClick":_vm.showHelpAppUrl}})],1),_c('b-col',{attrs:{"lg":"12"}},[_c('b-form-group',[_c('label',{staticClass:"form-control-label"},[_vm._v(" Dove ")]),_c('label',{staticClass:"form-control-label helpLabel",on:{"click":_vm.showHelpLocation}},[_vm._v(" (Istruzioni) ")]),_c('appointment-location-list',{attrs:{"locations":_vm.locations},on:{"remove-item":_vm.onRemoveItem,"edit-item":_vm.onEditItem}}),_c('b-select',{class:{
                            'mt-3': _vm.locations.length > 0
                          },attrs:{"options":_vm.locationTypes},on:{"change":_vm.onSelect_LocationChanged},model:{value:(_vm.inputSelectValue),callback:function ($$v) {_vm.inputSelectValue=$$v},expression:"inputSelectValue"}})],1)],1),_c('b-col',{attrs:{"lg":"12"}},[_c('b-form-group',[_c('label',{staticClass:"form-control-label"},[_vm._v(" Visualizza in Pagina Prenotazione Generale ")]),_c('label',{staticClass:"form-control-label helpLabel",on:{"click":_vm.showHelpIsVisibleInMainBookingPage}},[_vm._v(" (Istruzioni) ")]),_c('base-switch',{attrs:{"name":"Nome","value":_vm.isVisibleInMainBookingPage},on:{"input":function (value) {
                              this$1.$emit(
                                'update:isVisibleInMainBookingPage',
                                Boolean(value)
                              );
                            }}})],1)],1),_c('b-col',{attrs:{"lg":"12"}},[_c('b-form-group',[_c('label',{staticClass:"form-control-label"},[_vm._v(" Invio dei Promemoria ")]),_c('label',{staticClass:"form-control-label helpLabel",on:{"click":_vm.showHelpReminders}},[_vm._v(" (Istruzioni) ")]),_c('base-switch',{attrs:{"name":"IsReminderEnabled","value":_vm.isReminderEnabled,"label":""},on:{"input":function (value) {
                              this$1.$emit(
                                'update:isReminderEnabled',
                                Boolean(value)
                              );
                            },"check-changed":_vm.onBeforeIsReminderEnabledToggle}}),(_vm.isReminderEnabled)?_c('div',{staticClass:"pl-3 ml-2 reminder-hours-details"},[_vm._v(" Quante ore prima vuoi inviare il promemoria? "),_c('base-input',{attrs:{"name":"Ore Promemoria","inputClasses":"reminder-offset-hours","rules":{
                              integer: true,
                              min_value: 1,
                              max_value: 240
                            },"placeholder":"Ore Promemoria","value":_vm.reminderOffsetHours},on:{"input":function (value) {
                                this$1.$emit(
                                  'update:reminderOffsetHours',
                                  Number(value)
                                );
                              }}})],1):_vm._e()],1)],1),_c('b-col',{attrs:{"lg":"12"}},[_c('b-form-group',{staticClass:"mb-0 form-control-label",attrs:{"label":"","label-class":"","label-for":"about-form-textaria"}},[_c('label',[_vm._v(" Notifica personalizzata ")]),_c('label',{staticClass:"form-control-label helpLabel",on:{"click":_vm.showHelpCustomNotificationMessage}},[_vm._v(" (Istruzioni) ")]),(
                            _vm.currentSubscription &&
                              !_vm.currentSubscription.plan.hasCustomNotifications
                          )?_c('label',{staticClass:"form-control-label helpLabel",on:{"click":function($event){return _vm.showHelpWhyCustomNotificationMessageDisabled(
                              'Notifica Personalizzata'
                            )}}},[_vm._v(" (Perchè è disabilitato?) ")]):_vm._e()]),(_vm.currentSubscription)?_c('div',_vm._g({},
                          !_vm.currentSubscription.plan.hasCustomNotifications
                            ? {
                                click: function() {
                                  _vm.showHelpWhyCustomNotificationMessageDisabled(
                                    'Notifica Personalizzata'
                                  );
                                }
                              }
                            : {}
                        ),[_c('wyswyg',{attrs:{"disabled":!_vm.currentSubscription.plan.hasCustomNotifications,"value":_vm.customNotificationMessage},on:{"input":function (value) {
                              this$1.$emit(
                                'update:customNotificationMessage',
                                value
                              );
                            }}})],1):_vm._e()],1)],1)],1)]),_c('b-row',{staticClass:"pt-3",attrs:{"slot":"footer","align-v":"center"},slot:"footer"},[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[(_vm.id !== 0)?_c('b-button',{attrs:{"href":"javascript:;","variant":"link"},on:{"click":_vm.deleteAppointmentType}},[_vm._v("Elimina")]):_vm._e(),_c('b-button',{staticClass:"btn-primary",attrs:{"href":"javascript:;","variant":"button"},on:{"click":function($event){return _vm.saveAppType()}}},[_vm._v("Salva")])],1)],1)],1)],1)],1)],1)],1)],1),(
      _vm.selectedLocationType != undefined &&
        _vm.componentMapping[_vm.selectedLocationType.locationType]
    )?_c(_vm.componentMapping[_vm.selectedLocationType.locationType],{tag:"component",attrs:{"locationProp":_vm.selectedLocationType},on:{"canceled":function($event){_vm.selectedLocationType = undefined},"edited":_vm.locationEdited}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }