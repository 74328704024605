
























































import TimeTableTimeDetails from "@/components/TimeTable/TimeTableTimeDetails.vue";
import { cloneDeep } from "lodash";
import { DayAvailability } from "@/data/Models/Availability/DayAvailability";
import Vue, { PropType } from "vue";
import { TimeInterval } from "@/data/Models/TimeInterval";

export default Vue.extend({
  name: "time-table-row",
  components: { TimeTableTimeDetails },
  data() {
    return {
    };
  },
  props: {
    dayName: {
      type: String,
      default: ""
    },
    availabilities: {
      type: Array as PropType<Array<TimeInterval>>
    },
    showCopy: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    addTime() {
      this.$emit("day-time-table-add-interval");
    },
    removeTime(index: number) {
      this.$emit("day-time-table-remove-interval", index);
    },
   
    emitDayTimeTableCopied() {
      this.$emit("day-time-table-copied", this.availabilities);
    }
  }
});
