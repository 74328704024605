





















































import Vue, { PropType } from "vue";
import { assign } from "lodash";

import BaseAppTypeDetailsComponent from "@/components/AppointmentTypeDetails/BaseAppTypeDetailsComponent.vue";
import { getAvailabilitySets } from "@/data/availabilitySet";
import { getCurrentSubscription } from "@/data/subscriptionService";
import {
  getPersonalAppTypeById,
  savePersonalAppType
} from "@/data/services/AppTypeServices/pesonalAppTypeService";
import { deleteAppointmentType } from "@/data/appointmentService";

//models
import {
  createDefaultPersonalAppType,
  PersonalAppType
} from "@/data/Models/Appointment/PersonalAppType";
import { createDefaultSubscription } from "@/data/Models/Subscription/Subscription";
import { AppTypeDiscriminiator, BaseAppType } from "@/data/Models/Appointment/BaseAppType";
import AppointmentLocationListVue from "./AppointmentLocationList.vue";
import { ApplicationUserAppointmentType } from "@/data/Models/Appointment/ApplicationUserAppointmentType";

export default Vue.extend({
  name: "personal-app-type-details-component",
  components: { BaseAppTypeDetailsComponent },
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      availabilitySets: [] as { value: string; text: string }[],
      personalAppType: createDefaultPersonalAppType(),
      currentSubscription: createDefaultSubscription()
    };
  },
  async mounted() {
    await this.loadInterface();
  },
  watch: {
    async id() {
      await this.loadInterface();
    }
  },
  methods: {
    initPesonalAppType() {
      let newPersonalAppType = createDefaultPersonalAppType();
      let newUser: ApplicationUserAppointmentType = {
        applicationUsersId: this.$store.state.currentUser.userId,
        availabilitySetId: 0
      };
      newPersonalAppType.applicationUserAppointmentType.push(newUser);
      this.personalAppType = newPersonalAppType;
    },
    async loadInterface() {
      //Get Availability Sets
      let availabilitySetList = await getAvailabilitySets();
      this.availabilitySets = availabilitySetList.data.map(
        (element, index, array) => {
          return {
            value: element.id,
            text: element.name
          };
        }
      );
      
      //Get Appointment Type Details
      if (this.id != 0) {
        let personalAppType = await getPersonalAppTypeById(this.id);

        if (personalAppType) {
          this.personalAppType = personalAppType;
        }
      } else {
        this.initPesonalAppType();
      }

      //Get current subscription
      this.currentSubscription = await getCurrentSubscription();
    },
    async savePersonalAppType() {
      let validator = this.$refs.myValidator as any;
      let valid = await validator.validate();

      if (valid) {
        let result = await savePersonalAppType(this.personalAppType);

        if (this.id != result.data.id) {
          this.$router.push({
            name: "appointment_type_details",
             params: {
              id: result.data.id,
              appTypeDiscriminator: result.data.discriminator
            }
          });
        }

        this.$showAlertBarSaveSuccessfull();
        await this.loadInterface();
      }
    },
    async deletePersonalAppType() {
      await deleteAppointmentType(this.id, this.personalAppType.discriminator);
      await this.$showAlertBarSaveSuccessfull();
      this.$router.push({
        name: "appointment_types"
      });
    },
    async showHelpAvailabilities() {
      await this.$showHtmlModalMessage(
        "Disponibilità Orarie",
        `
        Qui puoi selezionare gli orari in cui vuoi che sia prenotabile questo tipo di appuntamento.<br/><br/>
        Gli orari li definisci nella sezione "Gestione Disponibilità".

        `
      );
    }
  
  }
});
