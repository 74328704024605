import store from "@/data/store.js";

export default {
  install(Vue) {
    Vue.prototype.$showAlertBar = function(message:string, variant:string) {
      this.$bvToast.toast(message, {
        variant: variant,
        solid: true,
        headerClass: "d-none",
        //toaster:"b-toaster-bottom-right"
      });
      // store
      //     .dispatch("updateGlobalAlertBarInfo", {
      //         message: message,
      //         variant: variant,//success,danger,warning,ecc
      //         show: true
      //     });
      // setTimeout(function () { Vue.prototype.$hideAlertBar(); }, 3000)
    };
    Vue.prototype.$hideAlertBar = function() {
      store.dispatch("updateGlobalAlertBarInfo", {
        message: "",
        variant: "", //success,danger,warning,ecc
        show: false
      });
    };
    Vue.prototype.$showAlertBarSaveSuccessfull = function() {
      this.$showAlertBar("Salvataggio effettuato correttamente.", "success");
    };
  }
};

// import store from "@/data/store.js";

// export default
//     {
//         install(Vue) {

//             Vue.prototype.$showAlertBar = function (message, variant) {
//                 store
//                     .dispatch("updateGlobalAlertBarInfo", {
//                         message: message,
//                         variant: variant,//success,danger,warning,ecc
//                         show: true
//                     });
//                 setTimeout(function () { Vue.prototype.$hideAlertBar(); }, 3000)

//             },

//                 Vue.prototype.$hideAlertBar = function () {
//                     store
//                         .dispatch("updateGlobalAlertBarInfo", {
//                             message: "",
//                             variant: "",//success,danger,warning,ecc
//                             show: false
//                         })
//                 },

//                 Vue.prototype.$showAlertBarSaveSuccessfull = function () {
//                     Vue.prototype.$showAlertBar("Salvataggio effettuato correttamente.", "success");

//                 }
//         }

//     }
