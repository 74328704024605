


















































































import Vue, { VueConstructor, PropType } from "vue";

export default Vue.extend({
  inheritAttrs: false,
  name: "base-radio",
  data() {
    return {
      focused: false
    };
  },
  props: {
    mode: {
      type: String,
      default: "aggressive"
    },

    error: {
      type: String
      //description: "Input error (below input)"
    },

    labelClasses: {
      type: String,
      //description: "Input label css classes",
      default: "form-control-label"
    },

    value: {
      type: [String, Number]
      //description: "Input value"
    },

    rules: {
      type: [String, Array, Object],
      //description: "Vee validate validation rules",
      default: ""
    },
    name: {
      type: String,
      //description: "Input name (used for validation)",
      default: ""
    },
    vid: {
      type: String,
      //description: "Vee validate object for confirmed rule",
      default: ""
    },
    helpLabelClasses: {
      type: String,
      default: "form-control-label helpLabel"
    },
    helpLabelText: {
      type: String,
      default: "(Istruzioni)"
    },
    showHelpLabel: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    listeners(): object {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur
      };
    },

    slotData(): object {
      return {
        focused: this.focused,
        error: this.error,
        ...this.listeners
      };
    }
  },

  methods: {
    updateValue(evt): void {
      let value = evt.target.value;
      this.$emit("input", value);
    },
    onFocus(evt) {
      this.focused = true;
      this.$emit("focus", evt);
    },
    onBlur(evt) {
      this.focused = false;
      this.$emit("blur", evt);
    },
    onHelpClick() {
      this.$emit("onHelpClick");
    }
  }
});
