























































































import TimeTableRow from "@/components/TimeTable/TimeTableRow.vue";
import WeekDaySelector from "@/components/TimeTable/WeekDaySelector.vue";
import * as availabilitySet from "@/data/availabilitySet";
import { parse } from "date-fns";
import BaseInput from "../Inputs/BaseInput.vue";
import BaseButton from "../BaseButton.vue";
import { cloneDeep } from "lodash";
import { getWeekDays, orderWeekDaysInItalian } from "@/util/dateManager";

import { TimeInterval } from "@/data/Models/TimeInterval";
import { ValidationProvider } from "vee-validate";

import Vue, { PropType } from "vue";
import { AvailabilitySet } from "@/data/Models/Availability/AvailabilitySet";
import { DayAvailability } from "@/data/Models/Availability/DayAvailability";

// interface TestInterface {
//   dowId: number;
//   timeIntervals: TimeInterval[];
// }

// class testClass {
//   dowId:number;
//   timeIntervals: TimeInterval[]

//   constructor()
//   {
//     this.dowId = -1;
//     this.timeIntervals = [];
//   }
// }

export default Vue.extend({
  name: "time-table",
  components: { TimeTableRow, WeekDaySelector },
  data() {
    return {
      parse,
      availabilitySet: {} as AvailabilitySet,
      //copiedDay: new testClass(),
      copiedDay: {
        dowId: -1,
        timeIntervals: [] as TimeInterval[]
      }
      // copiedDay: {} as { dowId: number; timeIntervals: TimeInterval[] },
      //copiedDay: Vue.observable({} as TestInterface),
      // copiedDowId: -1
    };
  },
  props: {
    id: {
      type: Number,
      default: 0
    },
    showFooterButtons: { type: Boolean, default: true },
    showNameInput: { type: Boolean, default: true },
    showTemplates: { type: Boolean, default: true }
  },
  watch: {
    id() {
      this.loadInterface();
    }
    // copiedDay: {
    //   handler() {
    //     console.log("TimeTable:"+this.copiedDay.dowId);
    //   },
    //   deep:true,
    //   immediate:true
    // }
  },
  methods: {
    async goToAvailabilities() {
      let confirmed = await this.$showConfirmDelete(
        `Sicuro di voler annullare?`
      );

      if (confirmed === true) {
        await this.$router.push("/availabilities");
      }
    },

    async saveAvailabilitySet() {
      let valid = await this.validate();
      // (await this.$refs.timeTableValObserver.validate()) &&
      // this.validateAvailabilitiesQty();
      if (valid) {
        let result = await availabilitySet.saveAvailabilitySet(
          this.availabilitySet
        );
        if (result) {
          let id = result.data.id;
          this.$emit("availability-updated", id);
          this.$router.push({
            name: "availability",
            params: { id: id }
          });
          await this.$showAlertBarSaveSuccessfull();
        }
      }
    },
    async validate() {
      let result = await (this.$refs.timeTableValObserver as InstanceType<
        typeof ValidationProvider
      >).validate();
      return result;
    },
    async deleteAvailabilitySet() {
      let confirmed = await this.$showConfirmDelete(
        `Stai per cancellare il set di disponibilità '${this.availabilitySet.name}'.<br/>Sicuro di voler procedere?`
      );

      if (confirmed) {
        let id = this.id;
        let result = await availabilitySet.deleteAvailabilitySet(id);
        this.$emit("availability-updated");
        this.$router.push({
          name: "availabilities"
        });
        await this.$showAlertBarSaveSuccessfull();
      }
    },
    getDayObject(id) {
      if (this.availabilitySet.days) {
        //console.log(new Date());
        return this.availabilitySet.days.find(d => d.dowId === id);
        // const index = this.availabilitySet.days.findIndex(d => d.dowId === id);
        // const day = index > -1 ? this.availabilitySet.days[index] : null;
        // return day;
      }
    },
    async loadInterface() {
      let result = await availabilitySet.GetAvailabilitySetByIdForUI(this.id);

      if (result.id > 0 || this.id == 0) {
        //Ordino gli orari
        result.days.forEach(day => {
          day.availabilities.sort((a, b) => {
            let aTime = parse(a.startHour, "HH:mm", new Date());
            let bTime = parse(b.startHour, "HH:mm", new Date());
            let dateResult = aTime.getTime() - bTime.getTime();
            return dateResult;
          });
        });
        orderWeekDaysInItalian(result.days, "dowId");
        //Se ho trovato il set o ne sto creando uno nuovo
        this.availabilitySet = result;
        return;
      }

      if (result.id == 0 && this.id > 0) {
        //not found
        this.$router.push({
          name: "appointment_types"
        });
        return;
      }
    },
    // async loadInterface() {
    //   let result = await availabilitySet.GetAvailabilitySetByIdForUI(this.id);

    //   if (result.data.id > 0 || this.id == 0) {
    //     //Ordino gli orari
    //     result.data.days.forEach(day => {
    //       day.availabilities.sort((a, b) => {
    //         let aTime = parse(a.startHour, "HH:mm", new Date());
    //         let bTime = parse(b.startHour, "HH:mm", new Date());
    //         let dateResult = aTime.getTime() - bTime.getTime();
    //         return dateResult;
    //       });
    //     });
    //     //Se ho trovato il set o ne sto creando uno nuovo
    //     this.availabilitySet = result.data;
    //     return;
    //   }

    //   if (result.data.id == 0 && this.id > 0) {
    //     //not found
    //     this.$router.push({
    //       name: "appointment_types"
    //     });
    //     return;
    //   }
    // },
    async confirmTemplate() {
      let confirmed = await this.$showConfirmDelete(
        `Se procedi gli orari che hai inserito verranno sostituiti con quelli di questo template, vuoi procedere?`
      );
      return confirmed;
    },

    async setOfficeHoursTemplate() {
      if (await this.confirmTemplate()) {
        let availabilities: TimeInterval[] = [
          {
            startHour: "09:00",
            endHour: "13:00"
          },
          {
            startHour: "14:00",
            endHour: "18:00"
          }
        ];
        this.setWeekdaysTemplate(availabilities);
      }
    },
    async setMorningHoursTemplate() {
      if (await this.confirmTemplate()) {
        let availabilities: TimeInterval[] = [
          {
            startHour: "09:00",
            endHour: "13:00"
          }
        ];
        this.setWeekdaysTemplate(availabilities);
      }
    },
    async setAfternoonHoursTemplate() {
      if (await this.confirmTemplate()) {
        let availabilities: TimeInterval[] = [
          {
            startHour: "14:00",
            endHour: "18:00"
          }
        ];
        this.setWeekdaysTemplate(availabilities);
      }
    },
    setWeekdaysTemplate(availabilities: TimeInterval[]) {
      //let result = [];
      let result = new Array<{
        dowId: number;
        availabilities: TimeInterval[];
      }>();
      for (let i = 0; i <= 6; i++) {
        let dayAvailablities = new Array<TimeInterval>();
        if (i !== 0 && i !== 6) {
          //Se un giorno della settimana passo le disponibilità altrimenti passo array vuoto.
          dayAvailablities = cloneDeep(availabilities);
        }

        result.push({
          dowId: i,
          availabilities: dayAvailablities
        });
      }
      result.forEach(e => {
        let day = this.availabilitySet.days.find(s => s.dowId == e.dowId);
        if (day) {
          day.availabilities = e.availabilities;
        }
      });
    },
    // onDayTimeTableUpdated(oldDayTimeTable, newDayTimeTable) {
    //   Object.assign(oldDayTimeTable, newDayTimeTable);
    // },
    onDayTimeTableCopied(dowId: number, event: TimeInterval[]) {
      this.copiedDay.dowId = dowId;
      this.copiedDay.timeIntervals = event;
      this.$bvModal.show("time-table-copy-modal");
    },
    onWeekDaySelectorDaySelected(selectedDowIds: number[]) {
      this.$bvModal.hide("time-table-copy-modal");
      selectedDowIds.forEach(selectedDowId => {
        let day = this.availabilitySet.days.find(
          d => d.dowId === selectedDowId
        );
        if (day !== undefined) {
          day.availabilities = cloneDeep(this.copiedDay.timeIntervals);
        }
      });
      //Applico ai giorni selezionati gli orari del giorno di partenza
      //       selectedDowIds.forEach(function(element) {
      // this.ava
      //       }, this);
      //this.availabilitySet.days.indexOf();
    },
    onIntervalAddRequest(dowId: number) {
      let selectedDay = this.availabilitySet.days.find(x => x.dowId == dowId);
      if (selectedDay != undefined) {
        selectedDay.availabilities.push({
          startHour: "",
          endHour: ""
        });
      }
    },
    onIntervalRemoveRequest(dowId: number, index) {
      let selectedDay = this.availabilitySet.days.find(x => x.dowId == dowId);
      if (selectedDay != undefined) {
        selectedDay.availabilities.splice(index, 1);
      }
    }
  },
  computed: {
    // weekDays() {
    //   return getWeekDays();
    // }
    // weekDaysWithAvSet():
    //   | {
    //       dayAvailability: DayAvailability | null;
    //       dowId: number;
    //       dayName: string;
    //     }[]
    //   | undefined {
    //   if (!this.availabilitySet.days) {
    //     return undefined;
    //   }
    //   let result = this.weekDays.map(day => {
    //     const availabilityDay = this.availabilitySet.days.find(
    //       d => d.dowId === day.dowId
    //     );
    //     return {
    //       ...day,
    //       dayAvailability: availabilityDay ? availabilityDay : null
    //     };
    //   });
    //   return result;
    // }
  },
  created: async function() {
    await this.loadInterface();
  }
});
