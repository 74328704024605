import { PersonalAppType } from "@/data/Models/Appointment/PersonalAppType";
import apiClient from "@/util/ApiClient";
import { AxiosResponse } from "axios";

export const getPersonalAppTypeById = async function(id:number):Promise<PersonalAppType> {
    let result = await apiClient.get(`PersonalAppType/GetPersonalAppTypeById`, {
      params: { id }
    });
  
    return result.data;
  };

  export const savePersonalAppType = async function(appType:PersonalAppType) {
    //inietto i tipi corretti per la deserializzazione polimorfica
  
    appType.locationTypes.forEach(appointmentLocationType => {
      var baseType = "SchedeoV2.CL.Data.DTO.AppointmentLocationTypes";
      appointmentLocationType.$type = `${baseType}.${appointmentLocationType.locationType}DTO`;
    });
    return apiClient.post("PersonalAppType/SavePersonalAppType", appType);
  };

  export const getPersonalAppointmentTypes = async function():Promise<Array<PersonalAppType>> {
    let result = await apiClient.get("Appointment/GetPersonalAppointmentTypes");
    return result.data;
  };

  export const deletePersonalAppType = async function(id:number) {
    return apiClient.delete("PersonalAppType/DeletePersonalAppType", {
      params: { id }
    });
  };