<template>
  <div class="text-center p-5">
    <b-img style="max-width:200px;" class="pb-7" src="/img/brand/Logo-schedeo-V2-Transp.png" fluid />

    <!-- <img src="img/brand/Logo-schedeo-V2-Transp3.png" class="p-1 m-2" /> -->
    <div style="max-width:500px;" class="ml-auto mr-auto">
      <b-img src="/img/images/undraw_server_down_s4lk.png" fluid />
    </div>
    <h1>Schedeo è in Manutezione </h1>
    <div class=" no-appointment">
      
      Il sito è attualmente in manutenzione e non è possibile accedere ai servizi.<br>
      <br>
      Torneremo online a breve, riprova tra poco!
    </div>
    
  </div>
</template>

<script>
export default {};
</script>

<style>
body{
background: white;    
}
</style>
