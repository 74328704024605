import { RoundRobinAppType } from "@/data/Models/Appointment/RoundRobinAppType";
import apiClient from "@/util/ApiClient";
import { AxiosResponse } from "axios";
import { TeamWithAppTypeSummary } from "@/data/Models/Appointment/TeamWithAppTypeSummary";


  export const saveRoundRobinAppType = async function(appType:RoundRobinAppType) {
    //inietto i tipi corretti per la deserializzazione polimorfica
  
    appType.locationTypes.forEach(appointmentLocationType => {
      var baseType = "SchedeoV2.CL.Data.DTO.AppointmentLocationTypes";
      appointmentLocationType.$type = `${baseType}.${appointmentLocationType.locationType}DTO`;
    });
    return apiClient.post("RoundRobinAppType/SaveRoundRobinAppType", appType);
  };

export const getRoundRobinAppTypeById = async function(id:number):Promise<RoundRobinAppType> {
    let result = await apiClient.get(`RoundRobinAppType/GetRoundRobinAppTypeById`, {
      params: { id }
    });
  
    return result.data;
  };



//   export const getTeamAppointmentTypes = async function(): Promise<TeamWithAppTypeSummary[]> {
//     let result = await apiClient.get("TeamAppType/GetTeamAppointmentTypes");
//     return result.data;
//   };

//   export const deleteTeamAppType = async function(id:number) {
//     return apiClient.delete("TeamAppType/DeleteTeamAppType", {
//       params: { id }
//     });
//   };

//   export const canCreateTeamAppType = async function(): Promise<Boolean> {
//     let result = await apiClient.get("TeamAppType/CanCreateTeamAppType");
//     return result.data;
//   };
  

  