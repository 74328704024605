import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "@/data/store.js";

import { handleError } from "@/util/errorHandler";

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  mode: "history",
  base: "/",
  routes, // short for routes: routes
  linkActiveClass: "active",
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.loggedIn) {
      next({
        path: "/login",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

router.afterEach((to, from) => {
  if (Vue.gtm.enabled()) {
    dataLayer.push({ event: "consenso_acquisito" });
  }
});

router.onError(async err => {
  handleError(err, router.app);
});

export default router;
