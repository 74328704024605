<template>
  <!-- :editorOptions="editorOptions" -->
  <div :class="{disabledStyle: disabled, unselectable: disabled}">
  <vue-editor
    :value="value"
    @input="onEditorUpdate"
    :editorToolbar="customToolbar"
    :editorOptions="editorOptions"
    :disabled="disabled"
  />
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  name: "Wyswyg",
  components: { VueEditor },
  data() {
    return {
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
      ],
      editorOptions: {
        formats: ["bold", "underline", "italic", "link", "list"]
      }
    };
  },

  props: {
    value: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onEditorUpdate(newVal) {
      //           ^^^^^^
      //           <vue-editor> input event payload
      this.$emit("input", newVal);
    }
  }
};
</script>

<style>
/* fix  style scope */
strong {
  font-weight: bold;
}
.disabledStyle{
  opacity: 0.3;
}
</style>
