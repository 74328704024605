<template>
  <div>
    <b-modal
      id="address-location-modal"
      title="Luogo"
      headerClass="modalHeader"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :hide-header-close="true"
      v-model="show"
    >
      <template #default="{ }">
        <base-input label="Indirizzo" v-model="location.address">
        </base-input>
      </template>

      <template #modal-footer="{ }">
        <div class="d-flex justify-content-end">
        <base-button class="mt-3" type="link" @click="emitCancelled">Annulla</base-button>
        <base-button class="mt-3" type="primary" @click="emitEdit">Ok</base-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {cloneDeep} from "lodash"

export default {
  data() {
    return {
      location: {}
    };
  },

  props: {
    locationProp: {
      type: Object
    }
  },
  computed:{
      /**
       * @returns {boolean}
       */
      show(){
          return this.isValidObject(this.locationProp);
      }
  },
  mounted() {
    this.loadUI();
  },
  watch: {
    locationProp() {
        this.loadUI();
    }
  },
  methods: {
    emitEdit(){
      this.$emit("edited",this.location);
    },
     emitCancelled(){
      this.$emit("canceled");
    },

    loadUI() {
      this.location = cloneDeep(this.locationProp);
   
    }
  }
};
</script>

<style></style>
