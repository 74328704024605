<template>
  <div v-if="location">
    <i
      :class="location.icon"
      :style="{ width: iconWidth }"
      class="pr-1 align-self-center"
    ></i>
    {{ location.locationLabel }}
    <div v-if="!location.hasGoogleCalendar" class="invalid-feedback" style="display:block; white-space:normal;">
      Per usare Google Meet devi collegare almeno un calendario google 
      <router-link :to="{name: 'calendars'}">(Clicca qui)</router-link>
      
      </div>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * @type {import('vue').PropType<{ icon:string, locationLabel:string,hasGoogleCalendar:boolean  }>}
     */
    location: {},
    iconWidth: {
      type: String,
      default: "20px"
    }
  }
};
</script>

<style></style>
