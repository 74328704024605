<template>
  <div v-if="location" class="d-flex">
    <i
      :class="location.icon"
      :style="{ width: iconWidth }"
      class="pr-1 align-self-center"
    ></i>
    <div class="cut-text">
      {{ location.locationLabel }}:
      {{ location.address }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * @type {import('vue').PropType<{ icon:string, locationLabel:string, address:string }>}
     */
    location: {},
    iconWidth: {
      type: String,
      default: "20px"
    }
  }
};
</script>

<style></style>
