import { BvModalEvent } from "bootstrap-vue";
import _Vue from "vue";

export function ModalManager(Vue: typeof _Vue, options?: any): void {
  Vue.prototype.$showAlert = function(message: string): void {
    this.$store.dispatch("updateGlobalAlertInfo", {
      message: message,
      visible: true,
      modalGradient: "danger",
      modalClasses: "modal-danger",
      okOnly: true,
      buttonType: "white",
      contentClasses: "pre text-center",
      iconClasses: "ni ni-bell-55 ni-3x",
      headerClasses: ""
    });
  };

  Vue.prototype.$showAlertSuccess = function(message: string): void {
    this.$store.dispatch("updateGlobalAlertInfo", {
      title: "",
      message: message,
      visible: true,
      modalGradient: "success",
      modalClasses: "modal-success",
      okOnly: true,
      buttonType: "white"
    });
  };

  Vue.prototype.$hideModal = function(): void {
    this.$store.dispatch("updateGlobalAlertInfo", {
      // title: "",
      // message: "",
      // visible: false
    });
  };

  Vue.prototype.$showConfirmDelete = async function(
    message: string
  ): Promise<boolean> {
    let vnodes = this.$createElement("div", {
      domProps: { innerHTML: message }
    });
    let options = {
      cancelTitle: "No",
      okTitle: "Si"
    };
    return await this.$bvModal.msgBoxConfirm(vnodes, options);
  };


  Vue.prototype.$showModalMessage = async function(
    title:string,
    message:string
    // , id
  ):Promise<void> {
    try {
      return await this.$bvModal.msgBoxOk(message, {
        title: title,
        headerClass: "modalHeader",
        contentClass: "pre"
        //id: id
      });
    } catch (ex) {
      // https://bootstrap-vue.org/docs/components/modal
      // Message Boxes will throw an error (promise rejection) if they are closed/destroyed before they are hidden.
      // Always include a .catch(errHandler) reject handler, event if using the async await style code.
      console.log(ex);
    }
  };

  Vue.prototype.$showHtmlModalMessage = async function(title:string, message:string):Promise<void> {
    let h = this.$createElement;
    const messageVNode = h("div", { domProps: { innerHTML: message } });

    return await this.$bvModal.msgBoxOk([messageVNode], {
      title: title,
      headerClass: "modalHeader"
    });
  };

  Vue.prototype.$showTimedModalMessage = async function(
    title:string,
    message:string,
    duration:number
  ):Promise<void> {
    this.$store.dispatch("updateGlobalAlertInfo", {
      title: title,
      message: message,
      visible: true,
      modalGradient: "", // "danger",
      modalClasses: "", //"modal-danger",
      okOnly: true,
      buttonType: "primary",
      contentClasses: "pre",
      preventClosingSeconds: duration,
      iconClasses: "",
      headerClasses: "modalHeader"
    });

    return new Promise((resolve, reject) => {
      this.$root.$on("bv::modal::hide", (bvEvent:BvModalEvent, modalId:string) => {
        if (modalId == "app-modal") {
          resolve();
        }
      });
    });
  };
}

// export default {
//   install(Vue) {

//   }
// };
