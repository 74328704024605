<template>
  <div class="main-content bg-default">


    <base-nav
      v-model="showMenu"
      :transparent="true"
      menu-classes="justify-content-end"
      class="navbar-horizontal navbar-main navbar-top navbar-dark"
      expand="lg"
    >
      <div slot="brand" class="navbar-wrapper">
        <b-navbar-brand to="/">
          <img src="img/brand/Logo-schedeo-V2-Transp3.png" class="p-1 m-2" />
        </b-navbar-brand>
      </div>

      <template>
        <div class="navbar-collapse-header">
          <b-row>
            <b-col cols="6" class="collapse-brand">
              <router-link to="/">
                <img src="img/brand/Logo-schedeo-V2-Transp.png" class="p-1" />
              </router-link>
            </b-col>
            <b-col cols="6" class="collapse-close">
              <button type="button" class="navbar-toggler" @click="closeMenu()">
                <span></span>
                <span></span>
              </button>
            </b-col>
          </b-row>
        </div>
        <b-navbar-nav class="align-items-lg-center ml-lg-auto">
          <b-nav-item to="/login">
            <i class="ni ni-key-25"></i>
            <span class="nav-link-inner--text">Login</span>
          </b-nav-item>
          <b-nav-item to="/register">
            <i class="ni ni-circle-08"></i>
            <span class="nav-link-inner--text">Registrazione</span>
          </b-nav-item>
        </b-navbar-nav>
      </template>
    </base-nav>

    <div class="main-content">
      <zoom-center-transition :duration="pageTransitionDuration" mode="out-in">
        <router-view></router-view>
      </zoom-center-transition>
    </div>

    <footer class="py-5 px-5 text-white contentFooter" id="footer-main">
      <b-row align-v="center" class="justify-content-lg-between ">
        <b-col lg="6">
          <div>
            Schedeo.it Srl<br />
            P.Iva: 10787600963
          </div>
        </b-col>
        <!---->
        <b-col lg="6">
          <div class="text-lg-right">
            <a href="https://www.schedeo.it/privacy-policy/" target="_blank">
              <span class="text-white">
                Privacy Policy
              </span>
            </a>
          </div>
          <!-- <b-nav  class="nav-footer justify-content-end">
          <b-nav-item href="https://www.schedeo.it/privacy-policy/"  target="_blank">
          <span class="text-white">
            Privacy Policy
            </span>
          </b-nav-item>
        </b-nav>  -->
        </b-col>
      </b-row>
    </footer>
  </div>
</template>
<script>
import { BaseNav } from "@/components";
import { ZoomCenterTransition } from "vue2-transitions";

export default {
  components: {
    BaseNav,
    ZoomCenterTransition,
  },
  props: {
    backgroundColor: {
      type: String,
      default: "black"
    }
  },
  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: "login-page"
    };
  },
  computed: {
    /**
     * @returns {string}
     */
    title() {
      return `${this.$route.name} Page`;
    }
  },
  methods: {
    closeMenu() {
      document.body.classList.remove("nav-open");
      this.showMenu = false;
    },
    setBackgroundColor() {
      document.body.classList.add("bg-default");
    },
    removeBackgroundColor() {
      document.body.classList.remove("bg-default");
    },
    updateBackground() {
      if (!this.$route.meta.noBodyBackground) {
        this.setBackgroundColor();
      } else {
        this.removeBackgroundColor();
      }
    }
  },
  beforeDestroy() {
    this.removeBackgroundColor();
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: function() {
        this.updateBackground();
      }
    }
  }
};
</script>
<style scoped>
.contentFooter {
  font-size: 0.875rem;
}
</style>
<style lang="scss">
$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.main-content .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-content .zoomOut {
  animation-name: zoomOut8;
}
</style>
