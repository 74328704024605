//import "@/data/Models/BillingDetail";
import "@/data/Models/User/IBillingDetail";
import { IBillingDetail } from "@/data/Models/User/IBillingDetail";
import { User } from "@/data/Models/User/User";
import apiClient from "../util/ApiClient";


export const insertUserObjective = async function(userObjective: string) {
  let result = await apiClient.post(
    "Account/InsertUserObjective",
    userObjective,
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );

  return result.data;
};

export const createUserFirstConfiguration = async function(
  model,
  appointmentTypeName,
  durationInMinutes
) {
  let result = await apiClient.post(
    "Account/CreateUserFirstConfiguration",
    model,
    {
      params: {
        appointmentTypeName: appointmentTypeName,
        durationInMinutes: durationInMinutes
      }
    }
  );

  return result.data;
};

/**
 *
 * @returns {Promise<UserDTO>}
 */
export const getUser = async function():Promise<User> {
  let result = await apiClient.get("Account/GetUser");
  
  return result.data;
};

// function compareKeys(a, b) {
//   var aKeys = Object.keys(a).sort();
//   var bKeys = Object.keys(b).sort();
//   return JSON.stringify(aKeys) === JSON.stringify(bKeys);
// }
// export interface IBillingDetail {
//   userId: number | null;
//   companyName: string;
//   firstName: string;
//   lastName: string;
//   fiscalCode: string;
//   vat: string;
//   address: string;
//   city: string;
//   zipCode:string;
//   pec:string;
//   codiceDestinatario:string;
// }

export const getBillingDetail = async function():Promise<IBillingDetail> {
  let response = await apiClient.get<IBillingDetail>("Account/GetBillingDetail");
  return response.data;
  //let result = Object.create(BillingDetail.prototype, Object.getOwnPropertyDescriptors(response.data));
  //return result;
  
};

export const setUser = async function(user) {
  return apiClient.post("Account/SetUser", user);
};

export const createToken = async function(email, password) {
  return apiClient.post("Account/CreateToken", {
    Email: email,
    Password: password
  });
};

export const refreshToken = async function(token, refreshToken) {
  return apiClient.post("Account/RefreshToken", {
    token: token,
    refreshToken: refreshToken
  });
};

export const changePassword = async function(currentPassword, newPassword) {
  return apiClient.post("Account/ChangePassword", {
    currentPassword: currentPassword,
    newPassword: newPassword
  });
};

export const register = async function(model) {
  return apiClient.post("Account/Register", model);
};

export const generateResetPasswordRequest = async function(model) {
  let params = {
    mail: model.email,
    callbackDomainUrl: model.callbackDomainUrl
  };

  return apiClient.post("Account/GenerateResetPasswordRequest", null, {
    params: params
  });
};

export const resetPassword = async function(model) {
  return apiClient.post("Account/ResetPassword", model);
};

export const setBillingDetail = async function(model:IBillingDetail):Promise<void> {

  //La dto dell'endpoint è un sottoinsieme di IBillingDetail, ma in questo momento va bene lo stesso perchè
  //quello che mi interessa è distinguere bene lato server le DTO e prevenire l'overposting. Se fosse necessario aggiungerò
  //delle DTO specifiche anche clientside ( con i relativi mapping)
  return apiClient.post("Account/SetBillingDetail", model);
};

// export const getPublicProfileFromUserUrl = async function(userUrl) {
//   let result = await apiClient.get("Account/GetPublicProfileFromUserUrl", {
//     params: { userUrl }
//   });
//   return result.data;
// };

export const getPublicProfileFromEventId = async function(eventId) {
  let result = await apiClient.get("Account/GetPublicProfileFromEventId", {
    params: { eventId }
  });
  return result.data;
};
