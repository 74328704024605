import {
  format as formatFns,
  parseISO,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  eachDayOfInterval
} from "date-fns";
import it from "date-fns/locale/it";

let italianDowIdOrder = [1, 2, 3, 4, 5, 6, 0];
//import ja from "date-fns/locale/ja";

//const locales = {it}

// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
// export default function (date, formatStr = 'PP') {
//   return format(date, formatStr, {
//     locale: it
//     //locale: locales[window.__localeId__] // or global.__localeId__
//   })
// }

export const schedeoFormatDate = function(date, formatStr = "PP") {
  //se è una stringa provo a convertirla prima di procedere in modo da non dover fare il doppio passaggio
  let dateToFormat;
  if (typeof date === "string") dateToFormat = parseISO(date);
  else dateToFormat = date;

  let result = formatFns(dateToFormat, formatStr, {
    locale: it
    //locale: locales[window.__localeId__] // or global.__localeId__
  });
  return result;
};

export const getDaysInMonth = function(
  year: number,
  month: number
): Array<{ day: number; month: number; year: number }> {
  //let dates = [];
  let dates = new Array<{ day: number; month: number; year: number }>();
  //ottengo il numero di giorni
  let daysInMonth = endOfMonth(new Date(year, month - 1)).getDate();
  //let end = endOfMonth(monthDate);
  for (let i = 1; i <= daysInMonth; i++) {
    dates.push({ day: i, month: month, year: year });
  }
  return dates;
};

export const toIsoDateString = function(date) {
  return formatFns(date, "yyyy-MM-dd");
};

export const getWeekDays = function(): Array<{
  dowId: number;
  dayName: string;
}> {
  const locale = it;
  const now = new Date();
  const weekDays = new Array<{ dowId: number; dayName: string }>();
  const start = startOfWeek(now, { locale });
  const end = endOfWeek(now, { locale });
  eachDayOfInterval({ start, end }).forEach(day => {
    weekDays.push({
      dowId: day.getDay(),
      dayName: formatFns(day, "EEEE", { locale })
    });
  });

  

  weekDays.sort((a, b) => {
    //faccio il sorting in modalità italiana per avere il lunedì come primo
    let italianDowIdOrder = [1, 2, 3, 4, 5, 6, 0];
    return italianDowIdOrder.indexOf(a.dowId)-italianDowIdOrder.indexOf(b.dowId);
  });

  return weekDays;
};

export const orderWeekDaysInItalian = function<T>(weekDays:Array<T>, dowIdPropName:string){
//dato un array di oggetti e la proprietà che contiene il dowId, ordino.

weekDays.sort((a, b) => {
  //faccio il sorting in modalità italiana per avere il lunedì come primo
  return italianDowIdOrder.indexOf(a[dowIdPropName])-italianDowIdOrder.indexOf(b[dowIdPropName]);
});
}

// export const getItalianWeekDayOrder = function(): Array<number> {
//   return [1, 2, 3, 4, 5, 6, 0];
// };
