



















import { getCurrentSubscription } from "@/data/subscriptionService";
import Vue, { PropType } from "vue";

//Components
import PersonalAppTypeDetailsComponent from "@/components/AppointmentTypeDetails/PersonalAppTypeDetailsComponent.vue";
import TeamAppTypeDetailsComponent from "@/components/AppointmentTypeDetails/TeamAppTypeDetailsComponent.vue";
import RoundRobinAppTypeDetailsComponent from "@/components/AppointmentTypeDetails/RoundRobinAppTypeDetailsComponent.vue";

import AddressLocationModal from "@/components/AppointmentTypeDetails/AddressLocationModal.vue";
import PhoneLocationModal from "@/components/AppointmentTypeDetails/PhoneLocationModal.vue";

import store from "@/data/store.js";

//DTO
import "@/data/Models/AppointmentLocationTypeDTO";

import {
  Subscription,
  createDefaultSubscription
} from "@/data/Models/Subscription/Subscription";
import { AppointmentType } from "@/data/Models/Appointment/AppointmentType";

export default Vue.extend({
  name: "appointment-type-details",
  components: {
    PersonalAppTypeDetailsComponent,
    TeamAppTypeDetailsComponent,
    RoundRobinAppTypeDetailsComponent
  },
  props: {
    id: {
      type: Number,
      default: 0
    },
    appTypeDiscriminator: {
      type: String
    },
    teamId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      selectedAppType: {
        id: 0,
        name: "",
        durationMinutes: 0.0,
        appUrl: "",
        applicationUsers: [store.state.currentUser.userId], //[parseInt(this.$store.state.currentUser.userId)],
        availabilitySetId: 0,
        isEnabled: false,
        customNotificationMessage: "",
        isVisibleInMainBookingPage: true,
        locationTypes: [] as any[],
        isReminderEnabled: false,
        reminderOffsetHours: 24
      } as any,
      availabilitySets: [] as { value: string; text: string }[],

      currentSubscription: createDefaultSubscription(),
      locationTypes: [
        { value: null, text: "Se vuoi, aggiungi il luogo dell'appuntamento" },
        { value: "AddressLocationType", text: "Indirizzo" },
        { value: "PhoneLocationType", text: "Telefono" },
        {
          value: "GoogleMeetLocationType",
          text: "Video Chiamata - Google Meet"
        }
      ],
      /**
       * @type {AppointmentLocationTypeDTO}
       */
      selectedLocationType: {} as any,
      inputSelectValue: null,
      componentMapping: {
        AddressLocationType: AddressLocationModal,
        PhoneLocationType: PhoneLocationModal
      }
    };
  },
  computed: {
    userUrl():string {
      return store.state.currentUser.userUrl;
    }
  },
  methods: {
    async loadInterface():Promise<void> {
      this.currentSubscription = await getCurrentSubscription();
    }
  },
  async mounted() {
    await this.loadInterface();
  },
  watch: {
    async id() {
     await this.loadInterface();
    }
  }
});
