import {
  BaseAppType,
  createDefaultBaseAppType
} from "@/data/Models/Appointment/BaseAppType";

export interface TeamAppType extends BaseAppType {
  teamOwnerId: string;
}

export function createDefaultTeamAppType(): TeamAppType {

    //Creo un'istanza della classe base e 
    //gli aggiungo il campo che manca
  let baseValue = createDefaultBaseAppType();

  let defaultValue: TeamAppType = {
    ...baseValue,
    teamOwnerId: ""
  };

  return defaultValue;
}
