export interface IBillingDetail {
  userId: number | null;
  companyName: string;
  firstName: string;
  lastName: string;
  fiscalCode: string;
  vat: string;
  address: string;
  city: string;
  zipCode: string;
  pec: string;
  codiceDestinatario: string;
  customerType?: CustomerType;
}

export function createDefaultIBillingDetail(): IBillingDetail {
  let defaultBillingDetail: IBillingDetail = {
    userId: null,
    companyName: "",
    firstName: "",
    lastName: "",
    fiscalCode: "",
    vat: "",

    address: "",
    city: "",
    zipCode: "",
    pec: "",
    codiceDestinatario: "",
   customerType: CustomerType.Company
  };

  return defaultBillingDetail;
}

export enum CustomerType {
  Company="Company",
  Private = "Private",
}

// export class BillingDetail {
//   userId: number | null;
//   companyName: string;
//   firstName: string;
//   lastName: string;
//   fiscalCode: string;
//   vat: string;
//   address: string;
//   city: string;
//   zipCode:string;
//   pec:string;
//   codiceDestinatario:string;

//   constructor() {
//     this.userId = null;
//     this.companyName = "";
//     this.firstName = "";
//     this.lastName = "";
//     this.fiscalCode = "";
//     this.vat = "";

//     this.address = "";
//     this.city = "";
//     this.zipCode = "";
//     this.pec = "";
//     this.codiceDestinatario = "";

//   }
// }
