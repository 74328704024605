<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top navbar-expand bg-gradient-success"
    :class="{ 'navbar-dark': type === 'default' }"
  
  >
  <!-- bg-gradient-success -->
    <a
      href="#"
      aria-current="page"
      class="
        h4
        mb-0
        text-white text-uppercase
        d-none d-lg-inline-block
        active
        router-link-active
      "
    >
      <!--  {{ $route.name }} -->
    </a>
    <!-- Navbar links -->
    <b-navbar-nav class="align-items-center ml-md-auto">
      <!-- This item dont have <b-nav-item> because item have data-action/data-target on tag <a>, wich we cant add -->
      <li class="nav-item d-sm-none">
        <a
          class="nav-link"
          href="#"
          data-action="search-show"
          data-target="#navbar-search-main"
        >
          <i class="ni ni-zoom-split-in"></i>
        </a>
      </li>
    </b-navbar-nav>

    <b-navbar-nav class="align-items-center ml-auto ml-md-0 mr-2 clickable">
      <div style="height: 44px"></div>

      <div class="pr-0" @click.prevent slot="title-container">
      </div>
    </b-navbar-nav>
    
  

    <b-navbar-nav class="align-items-start ml-auto ml-md-0">
      <div style="height: 44px"></div>

      <div class="pr-0" @click.prevent slot="title-container">
        <b-media no-body class="align-items-center">
          <span class="avatar avatar-sm rounded-circle">
            <i class="fas fa-user navbar-icon"></i>
          </span>
          <b-media-body class="ml-2 d-lg-block" style="color: white">
            <div class="mb-0 text-sm font-weight-bold">
              {{ this.$store.getters.currentUser.userName }}
            </div>
            <div
              class="mb-0 text-sm font-weight-bold clickable text-underline"
              @click="logout"
            >
              Disconnetti
            </div>
          </b-media-body>
        </b-media>
      </div>
    </b-navbar-nav>
  </base-nav>
</template>
<script>
//import { CollapseTransition } from "vue2-transitions";
import { BaseNav, Modal } from "@/components";
//import NotificationsViewer from "@/components/NotificationsViewer"

export default {
  components: {
  //  CollapseTransition,
    BaseNav,
    //Modal,
    //NotificationsViewer
  },
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)",
    },
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    logout() {
      this.$store.dispatch("clearLoginData");
      this.$router.push("/login");
    }
    
  },
};
</script>
<style scoped>
.bg-schedeo-dark{
  background-color: #454548;
}
.userDiv {
  color: white;
  font-size: 50px;
  background-color: red !important;
}
.navbar-icon{
   font-size: 30px;
}
/* .profilePic {
  font-size: 30px;
} */
.avatar {
  background-color: transparent !important;
}
.bell-counter{
    font-size: .7rem;
    font-weight: 300;
    padding: 2px 6px;
    position: absolute;
    right: 4px;
    bottom:-18px;
    background-color:#ffc107;
    border-radius:3px ;
}

.dn-bell{
font-size:30px;
}
.dn-bell-counter {
  font-size: 10px;
  font-weight: 300;
  padding: 0px 8px;
 
  background-color: #ffc107;
  border-radius: 3px;
  
}
.dn-notifications-container
{
padding-top:6px;
}
</style>