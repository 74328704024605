






























































































import Vue, { PropType } from "vue";

import BaseAppTypeDetailsComponent from "@/components/AppointmentTypeDetails/BaseAppTypeDetailsComponent.vue";
import TeamAvailabilitiesDetails from "@/components/AppointmentTypeDetails/TeamAvailabilitiesDetails.vue";

import { getTeamUserAvailabilitySets } from "@/data/availabilitySet";
import { getCurrentSubscription } from "@/data/subscriptionService";
import {
  getRoundRobinAppTypeById,
  saveRoundRobinAppType
} from "@/data/services/AppTypeServices/RoundRobinAppTypeService";

import { deleteAppointmentType } from "@/data/appointmentService";

//models
import {
  createDefaultRoundRobinAppType,
  RoundRobinAppType
} from "@/data/Models/Appointment/RoundRobinAppType";
import { createDefaultSubscription } from "@/data/Models/Subscription/Subscription";
import {
  AppTypeDiscriminiator,
  BaseAppType
} from "@/data/Models/Appointment/BaseAppType";
import AppointmentLocationListVue from "./AppointmentLocationList.vue";
import {
  ApplicationUserAppointmentType,
  createDefaultAUAT
} from "@/data/Models/Appointment/ApplicationUserAppointmentType";
import { TeamAvailabilitySet } from "@/data/Models/Availability/TeamAvailabilitySet";

export default Vue.extend({
  name: "team-app-type-details-component",
  components: { BaseAppTypeDetailsComponent, TeamAvailabilitiesDetails },
  props: {
    id: {
      type: Number,
      default: 0
    },
    teamId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      roundRobinAppType: createDefaultRoundRobinAppType(),
      currentSubscription: createDefaultSubscription(),
      selectedTeamAvailability: undefined as
        | ApplicationUserAppointmentType
        | undefined,
      teamAvailabilityMode: "",
      teamAvSets: [] as TeamAvailabilitySet[]
    };
  },
  async mounted() {
    await this.loadInterface();
  },

  watch: {
    async id() {
      await this.loadInterface();
    }
  },
  computed: {
    fullTeamAssignedToApp(): boolean {
      let organizatorIds = this.roundRobinAppType.applicationUserAppointmentType.map(
        x => x.applicationUsersId
      );
      let teamMembersIds = this.teamAvSets.map(x => x.userId);

      return teamMembersIds.every(val => organizatorIds.includes(val));
    },
    enrichedAuAt(): any[] {
      let result = this.roundRobinAppType.applicationUserAppointmentType.map(x => ({
        auat: x,
        organizerMail: this.getOrganizerMail(x.applicationUsersId),
        availabilitySetName: this.getAvSetName(x.availabilitySetId)
      }));
      return result;
    }
    
  },
  methods: {
    removeOrganizer(organizerId: number) {
      let index = this.roundRobinAppType.applicationUserAppointmentType.findIndex(
        auat => auat.applicationUsersId == organizerId
      );

      if (index > -1) {
        this.roundRobinAppType.applicationUserAppointmentType.splice(index, 1);
      }
    },
    onTeamAvailabilitiesEdited(auat: ApplicationUserAppointmentType) {
      //Verifico se l'organizator è già nella lista
      let index = this.roundRobinAppType.applicationUserAppointmentType.findIndex(
        item => item.applicationUsersId === auat.applicationUsersId
      );
      if (index > -1) {
        //In questo caso modifico
        this.roundRobinAppType.applicationUserAppointmentType[
          index
        ].applicationUsersId = auat.applicationUsersId;
        this.roundRobinAppType.applicationUserAppointmentType[
          index
        ].availabilitySetId = auat.availabilitySetId;
        // let result = this.locations.map(obj => {
        //   if (obj.id === location.id) {
        //     return cloneDeep(location);
        //   } else return obj;
        // });
      } else {
        //In questo caso aggiungo
        let newAuat: ApplicationUserAppointmentType = {
          applicationUsersId: auat.applicationUsersId,
          availabilitySetId: auat.availabilitySetId
        };
        this.roundRobinAppType.applicationUserAppointmentType.push(newAuat);
      }
      this.selectedTeamAvailability = undefined;
      //await this.saveAppType();
    },
    onAddOrganizatorBtnClicked() {
      this.teamAvailabilityMode = "insert";
      this.selectedTeamAvailability = createDefaultAUAT();
    },
    onEditOrganizatorBtnClicked(auat: ApplicationUserAppointmentType) {
      this.teamAvailabilityMode = "edit";
      this.selectedTeamAvailability = auat;
    },
   
    async loadInterface() {
      //Get Appointment Type Details
      if (this.id != 0) {
        let appType = await getRoundRobinAppTypeById(this.id);

        if (appType) {
          this.roundRobinAppType = appType;
        }
      }
      if (this.id == 0) {
        this.roundRobinAppType.teamOwnerId = this.teamId;
      }

      this.teamAvSets = await getTeamUserAvailabilitySets(
        this.roundRobinAppType.teamOwnerId
      );
      this.currentSubscription = await getCurrentSubscription();
    },
    async saveAppType() {
      let validator = this.$refs.myValidator as any;
      let valid = await validator.validate();
      
      if (valid) {
        let result = await saveRoundRobinAppType(this.roundRobinAppType);
        if (this.id != result.data.id) {
          this.$router.push({
            name: "appointment_type_details",
            params: {
              id: result.data.id,
              appTypeDiscriminator: result.data.discriminator
            }
          });
        }
        this.$showAlertBarSaveSuccessfull();
        await this.loadInterface();
      }
    },
    async deleteAppType() {
      await deleteAppointmentType(this.id, this.roundRobinAppType.discriminator);
      this.$router.push({
        name: "appointment_types"
      });
    },
    async showHelpOrganizators() {
      await this.$showHtmlModalMessage(
        "Organizzatori",
        `
          Qui puoi aggiugnere gli organizzatori e i loro orari.<br/><br/>
          Nella pagina di prenotazione gli orari disponibili verranno mostrati gli orari in cui almeno uno degli organizzatori è disponibile.<br/><br/>
          Nel caso in cui ci sia più di un organizzatore disponibile l'appuntamento verrà assegnato ad uno di loro.
          `
      );
    },
    getOrganizerMail(userId: number): string {
      if (this.teamAvSets == undefined || this.teamAvSets.length == 0)
        return "";
      let result = this.teamAvSets.filter(x => x.userId == userId);
      if (result.length == 0) return "";

      return result[0].userMail;
    },
    getAvSetName(setId: number): string {
      if (this.teamAvSets == undefined || this.teamAvSets.length == 0)
        return "";

      let result = this.teamAvSets
        .flatMap(teamAvailSet => teamAvailSet.availabilitySets)
        .find(availSet => availSet.setId === setId);

      return result != undefined ? result.setName : "";
    }
  }
});
