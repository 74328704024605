import _Vue from 'vue';

declare global {
  interface Window {
    google: any;
  }
}

const googleAuth = ((): any => {
  let config: any = {}

  const installClient = () => {
    const apiUrl = 'https://accounts.google.com/gsi/client';
    return new Promise<void>((resolve) => {
      const script: any = document.createElement('script');
      script.src = apiUrl;
      script.onreadystatechange = script.onload = () => {
        if (!script.readyState || /loaded|complete/.test(script.readyState)) {
          setTimeout(() => {
            resolve();
          }, 500);
        }
      };
      document.getElementsByTagName('head')[0].appendChild(script);
    });
  };


  const initClient = (gconfig: any) => {
    config = gconfig;
  };

  const Auth = function (this: any) {
    this.load = (config: any, prompt: string) => {
      installClient()
        .then(() => {
          return initClient(config);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    this.getAuthCode = (successCallback: any, errorCallback: any) => {
      return new Promise((resolve, reject) => {
        try {
          // const tokenClient = window.google.accounts.oauth2.initTokenClient({
          //   client_id: gauthOption.client_id,
          //   scope: gauthOption.scope,
          //   ux_mode: 'popup',
          //   prompt: 'consent',
          //   callback: (tokenResponse) => {
          //     debugger;
          //     resolve(tokenResponse.access_token);
          //   },
          // });
          // tokenClient.requestAccessToken();


          //valecass: qui la reference
          // secondo me di tutta sta classe serve solo questo
          //https://developers.google.com/identity/oauth2/web/guides/migration-to-gis#authorization_code_flow_examples
          const client = window.google.accounts.oauth2.initCodeClient({
            client_id: config.clientId,
            scope: config.scope,
            //select_account: true,
            ux_mode: 'popup',
            prompt: 'consent', //forse non serve
            callback: (tokenResponse) => {
              resolve(tokenResponse.code);
            },
            error_callback: (errorResponse) => {
              reject(errorResponse.type)
              //   switch (errorResponse.type){
              //     case "":
              //       reject
              //       break;
              //     case "":
              //       break;
              //     default:
              //       break;
              //   }
              // try {
              //   reject(errorResponse.message);
              // } catch {
              //   reject("Errore");
              // }
            }
          });
          client.requestCode();
        } catch (ex) {
          reject(ex);
        }

      });
    };
  };

  return new (Auth as any);
})();


function installGoogleAuthPlugin(Vue: typeof _Vue, options?: any): void {
  // set config
  let GoogleAuthConfig: any = null;
  const GoogleAuthDefaultConfig = {
    scope: 'profile email',
  };
  let prompt = 'select_account';
  if (typeof options === 'object') {
    GoogleAuthConfig = Object.assign(GoogleAuthDefaultConfig, options);
    if (options.scope) {
      GoogleAuthConfig.scope = options.scope;
    }
    if (options.prompt) {
      prompt = options.prompt;
    }
    if (!options.clientId) {
      // tslint:disable-next-line
      console.warn('clientId is required');
    }
  } else {
    // tslint:disable-next-line
    console.warn('invalid option type. Object type accepted only');
  }

  // Install Vue plugin
  Object.defineProperties(Vue.prototype, {
    $gAuth: {
      get: () => {
        return googleAuth;
      },
    },
  });
  googleAuth.load(GoogleAuthConfig, prompt);
}

// tslint:disable-next-line
export default installGoogleAuthPlugin;
