<template>
  <div>
    <label v-if="label" :class="labelClasses">
      {{ label }}
    </label>
    <div v-if="selectedOptionsData.length > 0" class="selected-container mb-4">
      <b-list-group>
        <b-list-group-item
          v-for="option in selectedOptionsData"
          :key="option.value"
          class="picked-item"
        >
          <div class="d-flex">
            <div class="flex-grow-1">{{ option.text }}</div>

            <div
              class="flex-grow-0 clickable"
              @click="removeSelectedItem(option.value)"
            >
              x
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>
    </div>
    <base-select
      name="Dove"
      :options="notSelectedOptionsData"
      @change="addSelectedItem"
    >
    </base-select>
  </div>
</template>
<script>
/**
 * @typedef {object} OptionItem
 * @property {number} value
 * @property {string} text
 * @property {boolean} selected
 */

export default {
  data() {
    return {
      /**
       * @type {Array<OptionItem>}
       */
      notSelectedOptionsData: [],

      /**
       * @type {Array<OptionItem>}
       */
      selectedOptionsData: []
    };
  },
  props: {
    /**
     * @type {Array<OptionItem>}
     */
    /** @type {import('vue').PropType<Array<OptionItem>>} */
    options: {
      type: Array,
      default: () => []
    },
    label: {
      type: String
    },

    labelClasses: {
      type: String,
      default: "form-control-label"
    }
  },

  mounted() {
    //Splitto la prop in due array distinti associati ai componenti
    this.notSelectedOptionsData = this.options.filter(
      o => o.selected === false
    );
    this.selectedOptionsData = this.options.filter(o => o.selected);
  },
  methods: {
    addSelectedItem(itemValue) {
      //trovo l'item
      let objToMove = this.notSelectedOptionsData.find(
        i => i.value === itemValue
      );

      this.moveObjectBetweenArray(
        this.notSelectedOptionsData,
        this.selectedOptionsData,
        objToMove
      );

      this.emitChange();
    },
    removeSelectedItem(itemValue) {
      //const predicate = (i, itemValue) => i.value === itemValue;
      let objToMove = this.selectedOptionsData.find(i => i.value === itemValue);

      this.moveObjectBetweenArray(
        this.selectedOptionsData,
        this.notSelectedOptionsData,
        objToMove
      );
      this.emitChange();
    },
    moveObjectBetweenArray(sourceArray, destArray, objectToMove) {
      //lo rimuovo dal source
      sourceArray.splice(sourceArray.indexOf(objectToMove), 1);

      //lo aggiungo ai selected
      destArray.push(objectToMove);

      destArray.sort((a, b) => {
        //oridno per text
        let textA = a.text.toUpperCase();
        let textB = b.text.toUpperCase();
        if (textA < textB) return -1;
        if (textA > textB) return 1;
        return 0;
      });
    },

    emitChange() {

      //Aggiungo il selected ai due array
      let selectedArray = this.selectedOptionsData.map(x => {
        x.selected = true;
        return x;
      });
      let notSelectedArray = this.notSelectedOptionsData.map(x => {
        x.selected = false;
        return x;
      });

      //joino i due array per ricostruirli correttamente
      let joinedArray = selectedArray.concat(notSelectedArray);

      this.$emit("change", joinedArray);
    }
  }
};
</script>
<style scoped>
.picked-item {
  border-color: #cad1d7;
  padding: 0.625rem 0.75rem 0.625rem 0.75rem;
}
</style>
