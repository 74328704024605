import VueRouter from "vue-router";
import { deletePersonalAppType } from "@/data/services/AppTypeServices/pesonalAppTypeService";
import { deleteTeamAppType } from "@/data/services/AppTypeServices/TeamAppTypeService";
import apiClient from "../util/ApiClient";
import { PublicAppointmentType } from "./Models/Appointment/PublicAppointmentType";
import "@/data/Models/AppointmentTypeDTO";

import { copyToClipboard } from "@/util/helpers";
import { AppTypeSummary } from "@/data/Models/Appointment/AppTypeSummary";
import { AppointmentType } from "@/data/Models/Appointment/AppointmentType";
import { AxiosResponse } from "axios";
import { AppTypeDiscriminiator } from "./Models/Appointment/BaseAppType";

// export const deleteAppointmentType = async function(id) {
//   return apiClient.delete("Appointment/DeleteAppointmentType", {
//     params: { id }
//   });
// };

export const deleteAppointmentType = async function(
  id: number,
  appTypeDiscriminator: AppTypeDiscriminiator
) {
  switch (appTypeDiscriminator) {
    case AppTypeDiscriminiator.PersonalAppointmentType:
      await deletePersonalAppType(id);
      break;
    case AppTypeDiscriminiator.TeamAppointmentType:
      await deleteTeamAppType(id);
      break;
    default:
      throw Error("AppTypeDiscriminiator non riconosciuto.");
  }
};

export const getPublicAppointmentTypeInfoFromUrl = async function(
  ownerUrl: string,
  appUrl: string
): Promise<PublicAppointmentType> {
  let result = await apiClient.get(
    "Appointment/GetPublicAppointmentTypeInfoFromUrl",
    { params: { ownerUrl, appUrl } }
  );
  return result.data;
};

/**
 *
 * @param {string} eventId
 * @returns {Promise<PublicAppointmentTypeDTO>}
 */
export const getPublicAppointmentTypeInfoFromEventId = async function(eventId) {
  let result = await apiClient.get(
    "Appointment/GetPublicAppointmentTypeInfoFromEventId",
    { params: { eventId } }
  );
  return result.data;
};

export const getPublicAppointmentTypes = async function(
  ownerUrl: string
): Promise<PublicAppointmentType[]> {
  let result = await apiClient.get("Appointment/GetPublicAppointmentTypes", {
    params: { ownerUrl }
  });
  return result.data;
};

export const getMainBookingPageUrl = function(
  router: VueRouter,
  ownerUrl: string,
  window: Window
): string {
  let to = {
    name: "main_booking_page",
    params: {
      ownerUrl: ownerUrl
    }
  };
  let relativeUrl = router.resolve(to).href;
  return `${window.location.protocol}//${window.location.host}${relativeUrl}`;
};

export const getBookingPageUrl = function(
  router: VueRouter,
  userUrl: string,
  appUrl: string,
  window: Window
): string {
  console.log("getBookingPageUrl appServ");
  let to = {
    name: "book",
    params: {
      ownerUrl: userUrl,
      appUrl: appUrl
    }
  };
  let relativeUrl = router.resolve(to).href;
  return `${window.location.protocol}//${window.location.host}${relativeUrl}`;
};

export const getPublicProfileFromOwnerUrl = async function(ownerUrl) {
  let result = await apiClient.get("Appointment/GetPublicProfileFromOwnerUrl", {
    params: { ownerUrl }
  });
  return result.data;
};

export const getPersonalAppointmentTypes = async function(): Promise<AppTypeSummary[]> {
  let result = await apiClient.get("Appointment/GetPersonalAppointmentTypes");
  return result.data;
};

export const setAppointmentTypeIsEnabled = async function(
  appTypeId: number,
  isEnabled: boolean
) {
  return apiClient.patch("Appointment/SetAppointmentTypeIsEnabled", {
    appTypeId: appTypeId,
    isEnabled: isEnabled
  });
};

export const discriminatorToItalianType = function(
  discriminator: AppTypeDiscriminiator
): String {
switch(discriminator){
  case AppTypeDiscriminiator.PersonalAppointmentType:
    return "Personale";
    case AppTypeDiscriminiator.TeamAppointmentType:
    return "Team";
    case AppTypeDiscriminiator.RoundRobinAppointmentType:
    return "Round Robin";
}

return "";

};
