
import Vue, { PropType } from "vue";
import { v4 as uuidv4 } from "uuid";

export default Vue.extend({
  name: "gloablMixins",
  methods: {
    generateUniqueId(): string {
      return uuidv4();

      // var start = new Date();
      // var stop = new Date();
      // let ms = Math.abs(start.getTime() - stop.getTime());
      // console.log(ms);

      // return id;
    },
    isValidObject(obj: Object) {
      return obj !== null && obj !== undefined && Object.keys(obj).length > 0;
    },
    formatToDate(date) {
      //let date = Date.parse(inputDate)
      if (!isNaN(Date.parse(date))) {
        return new Date(date).toLocaleDateString("it-IT", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric"
        });
      }
    },
    formatMoney(number: number) {
      if (!isNaN(number))
        return number.toLocaleString("it-IT", {
          style: "currency",
          currency: "eur"
        });
    }
  }
});
