/*!

=========================================================
* BootstrapVue Argon Dashboard - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/bootstrap-vue-argon-dashboard
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from "vue";
import DashboardPlugin from "./plugins/dashboard-plugin";
import App from "./App.vue";

// router setup
import router from "./routes/router";

import store from "./data/store.js";
import VueAxios from "vue-axios";
import apiClient from "@/util/ApiClient";
import * as errorHandler from "@/util/errorHandler";
//import modalManager from "@/util/modalManager";
import { ModalManager } from "./util/modalManager";
import alertBarManager from "@/util/alertBarManager";
import GAuth from "@/util/google-auth/google-auth-client";

import VCalendar from "v-calendar";
import VueFormWizard from "vue-form-wizard";
import  "@/util/customValidationRules";

import VueGtm from "@gtm-support/vue2-gtm";
import { v4 as uuidv4 } from "uuid";

// import VuePlyr from 'vue-plyr'
// import 'vue-plyr/dist/vue-plyr.css'

// Importing the global css file
import "@/assets/custom_css/generic.scss";

import { Button, Table, TableColumn, Collapse,CollapseItem } from "element-ui";
// import msalPlugin from "@/plugins/ms-graph/msalPlugin";
// import msalConfig from "@/plugins/ms-graph/msalConfig";

Vue.use(VCalendar, {});

const gauthOption = {
  clientId:
    "998502048155-qvea7umm99jucld8iu9g1bkaoqqhc85j.apps.googleusercontent.com",
  scope:
    "https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.readonly",
  prompt: "consent",
  access_type: "offline",
  plugin_name:"SchedeoV2"
};

// Vue.use(msalPlugin, msalConfig)
Vue.use(GAuth, gauthOption);

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueAxios, apiClient);
Vue.use(require("vue-moment"));

//Vue.use(modalManager);
Vue.use(ModalManager);

Vue.use(alertBarManager);

//element https://element.eleme.io/#/en-US/component/quickstart
Vue.use(Button);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Collapse);
Vue.use(CollapseItem);

// Vue.use(VuePlyr, { plyr: {} });

Vue.mixin({
  methods: {
    isObjectEmpty(someObject) {
      return !Object.keys(someObject).length;
    },

    formatMoney(number) {
      //////////////////////////////////
      //importato in global mixing TS: quando non ci sono più riferimenti a questo, cancellare.
      //////////////////////////////////
      if (!isNaN(number))
        return number.toLocaleString("it-IT", {
          style: "currency",
          currency: "eur"
        });
    },
    formatToDate(date) {
      //////////////////////////////////
      //importato in global mixing TS: quando non ci sono più riferimenti a questo, cancellare.
      //////////////////////////////////

      //let date = Date.parse(inputDate)
      if (!isNaN(Date.parse(date))) {
        return new Date(date).toLocaleDateString("it-IT", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric"
        });
      }
    },
    isValidObject(obj) {
      return obj !== null && obj !== undefined && Object.keys(obj).length > 0;
    },
  }
});

Vue.use(VueGtm, {
  id: "GTM-KCM3VJL", // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  // queryParams: {
  //   // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
  //   gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
  //   gtm_preview: 'env-4',
  //   gtm_cookies_win: 'x'
  // },
  defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  nonce: "2726c7f26c", // Will add `nonce` to the script tag
  enabled: true, //getCookie("cookie_bar_accepted") === "true", //true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  //ignoredViews: ["homepage"], // Don't trigger events for specified router names (optional)
  trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
});

//Global Variables
Vue.prototype.$utm = {};


/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: h => h(App),
  router,
  store,
  //modalManager
});

Vue.config.errorHandler = function(err, vm, info) {
  errorHandler.handleError(err, vm);
  //alert(err.message + err.stack);
  // handle error
  // `info` is a Vue-specific error info, e.g. which lifecycle hook
  // the error was found in. Only available in 2.2.0+
};
