<template>
  <b-list-group>
    <b-list-group-item
      v-for="item in enrichedLocationData"
      :key="item.id"
      class="picked-item"
    >
      <div class="d-flex align-items-center flex-column flex-sm-row">
        <div class="flex-grow-1 item-description p-1">
            
            <component
            v-if="
            item.locationType != undefined &&
                item.itemComponent
            "
            v-bind:is="item.itemComponent"
            :location="item"
            iconWidth="20px"
            class="py-2"
          ></component>
          
        </div>
        <div v-if="item.editable"
          class="flex-grow-0 clickable py-2 pr-sm-2 link-no-underline edit-button "
          @click="emitEditItem(item.id)"
        >
          Modifica
        </div>

        <div
          class="flex-grow-0 clickable py-2 pl-sm-2 link-no-underline delete-button"
          @click="emitRemoveItem(item.id)"
        >
        Elimina
          <!-- <i data-v-3f20fe37="" class="onlyIconBtnGrey far fa-trash-alt"></i> -->
        </div>
      </div>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import AddressLocationItem from "@/components/AppointmentTypeDetails/AppointmentLocationListItems/AddressLocationItem.vue";
import GoogleMeetLocationItem from "@/components/AppointmentTypeDetails/AppointmentLocationListItems/GoogleMeetLocationItem.vue";
import PhoneLocationItem from "@/components/AppointmentTypeDetails/AppointmentLocationListItems/PhoneLocationItem.vue";

import {cloneDeep} from "lodash";


export default {
  //components: { AddressLocationItem, PhoneLocationItem },
  data() {
    return {
      locationTypeMapper: [
        {
          type: "AddressLocationType",
          itemComponent: AddressLocationItem,
          locationLabel: "Indirizzo",
          icon: "fas fa-map-marker-alt",
          editable: true
        },
        {
          type: "GoogleMeetLocationType",
          itemComponent: GoogleMeetLocationItem,
          locationLabel: "Google Meet",
          icon: "fab fa-google",
          editable: false
        },
        {
          type: "PhoneLocationType",
          itemComponent: PhoneLocationItem,
          locationLabel: "Telefono",
          icon: "fas fa-mobile-alt",
          editable: true
        }
      ],
      enrichedLocationData: []
    };
  },
  props: {
    /** @type {import('vue').PropType<Array<AppointmentLocationTypeDTO>>} */
    locations: {
      type: Array
    }
  },
  watch:{
      locations(){
          this.enrichLocationPropWithComponents();
      }
  },

  mounted(){
    this.enrichLocationPropWithComponents();  
  },
  methods: {
    emitRemoveItem(itemId) {
      this.$emit("remove-item", itemId);
    },
    emitEditItem(itemId) {
      this.$emit("edit-item", itemId);
    },
    
    enrichLocationPropWithComponents() {
//In questo metodo trasferisco tutte le info del locationTypeMapper nell'array che uso per la UI
// così semplifico tutti i passaggi successivi evitando di dover trovare ogni volta la corrispondenza
// tra mapper e item da passare ai componenti. uso sempre enriched...
      this.enrichedLocationData = this.locations.map(l => {

        //clono altrimenti aggiungo le proprietà agli oggetti originali, 
        //il map restituisce un nuov array ma gli oggetti sono sempre ref
        /**
         * @type {AppointmentLocationTypeDTO}
         */
          let clonedLocation = cloneDeep(l);

          let mapperItem = this.locationTypeMapper.find(i=>i.type === clonedLocation.locationType);
          clonedLocation.itemComponent = mapperItem.itemComponent;
          clonedLocation.locationLabel = mapperItem.locationLabel;
          clonedLocation.icon = mapperItem.icon;
          clonedLocation.editable = mapperItem.editable;

          return clonedLocation;
      });


    }
  }
};
</script>

<style scoped>
.child-item-icon-slot{
    width:20px;
}

.delete-button-icon {
  font-size: 1.75rem;
  padding-bottom: 5px;
}
.delete-button{
    font-size: 0.9rem;
}
.edit-button {
  font-size: 0.9rem;
  /* border-right: 1px solid silver; */
}
.item-description {
  font-size: 0.9rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}
.list-group-item {
  padding: 0px 1rem;
  border:1px solid #cad1d7;
}
</style>
