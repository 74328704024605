<template>
  <footer class="footer px-4 footerContent">
    <b-row align-v="center" class="justify-content-lg-between ">
      <b-col lg="6">
        Schedeo.it Srl<br> P.Iva: 10787600963

       
      </b-col>
      <b-col lg="6">
        <b-nav align="center" class="nav-footer justify-content-lg-end">
          <b-nav-item href="https://www.schedeo.it/privacy-policy/"  target="_blank">
            Privacy Policy
          </b-nav-item>
          
        </b-nav>
      </b-col>
    </b-row>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style>
.footerContent{
  font-size: 0.875rem;
}
</style>
