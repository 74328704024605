export const getQueryStringValue = function(valueName) {
  try {
    let qsValue = new URL(location.href).searchParams.get(valueName);
    return qsValue;
  } catch (e) {
    return "";
  }
};
export const groupBy = (key, array) => {
  array.reduce((accumulator, item) => {
    let group = item[key];
    if (group in accumulator) {
      //Se esiste già il gruppo aggiungo l'item
      return { ...accumulator, [group]: accumulator[group].concat(item) };
    } else return { ...accumulator, [group]: [item] };
  });
};

export const getSafe = function(fn, defaultVal=undefined) {
  // use it like this
  //getSafe(() => obj.a.lot.of.properties);

  // or add an optional default value
  //getSafe(() => obj.a.lot.of.properties, 'nothing');
  try {
    return fn();
  } catch (e) {
    return defaultVal;
  }
};
export const delay = function(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
};

/**
 *
 * @param {string} text Text to be copied to the clipboard
 */
export const copyToClipboard = function(text) {
  if (navigator && navigator.clipboard != undefined) {
    navigator.clipboard.writeText(text);
  }
};


export const getCookie = function(name) {
  let cookieValue: null | string = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

/**
 *
 * @param {string} name
 * @param {string} value
 * @param {number} days
 */
export const setCookie = function(name, value, days) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const isValidObject = function isValidObject(obj) {
  return obj !== null && obj !== undefined && Object.keys(obj).length > 0;
};

