import {
  TeamBaseAppType,
  createDefaultTeamBaseAppType
} from "@/data/Models/Appointment/TeamBaseAppType";

export interface RoundRobinAppType extends TeamBaseAppType {
}

export function createDefaultRoundRobinAppType(): RoundRobinAppType {

  let baseValue = createDefaultTeamBaseAppType();

  let defaultValue: RoundRobinAppType = {
    ...baseValue
  };

  return defaultValue;
}
