var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"myValidator"},[_c('b-modal',{attrs:{"id":"team-availabilities-modal","title":"Disponibilità Team","headerClass":"modalHeader","no-close-on-backdrop":true,"no-close-on-esc":true,"hide-header-close":true,"no-enforce-focus":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('b-row',[_c('b-col',[_c('label',{staticClass:"form-control-label"},[_vm._v(" Organizzatore ")]),(_vm.mode == 'insert')?_c('base-select',{attrs:{"name":"Organizzatore","options":_vm.toSelectObject(_vm.addableTeamMembers, 'userId', 'mail'),"rules":{
                oneOf: _vm.toSelectObject(
                  _vm.addableTeamMembers,
                  'userId',
                  'mail'
                ).map(function (a) { return a.value; })
              },"mode":"eager"},on:{"change":function($event){_vm.localAuat.availabilitySetId = -1}},model:{value:(_vm.localAuat.applicationUsersId),callback:function ($$v) {_vm.$set(_vm.localAuat, "applicationUsersId", $$v)},expression:"localAuat.applicationUsersId"}}):_vm._e(),_c('div',[(_vm.mode == 'edit')?_c('base-input',{attrs:{"readonly":"","value":_vm.getOrganizatorMail(_vm.localAuat.applicationUsersId)}}):_vm._e()],1),_c('label',{staticClass:"form-control-label"},[_vm._v(" Disponibilità orarie ")]),_c('base-select',{attrs:{"name":"Disponibilità orarie","options":_vm.toSelectObject(_vm.selectedUserAvSets, 'setId', 'setName'),"rules":{
                oneOf: _vm.toSelectObject(
                  _vm.selectedUserAvSets,
                  'setId',
                  'setName'
                ).map(function (a) { return a.value; })
              },"mode":"eager"},model:{value:(_vm.localAuat.availabilitySetId),callback:function ($$v) {_vm.$set(_vm.localAuat, "availabilitySetId", $$v)},expression:"localAuat.availabilitySetId"}})],1)],1)]}},{key:"modal-footer",fn:function(ref){return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('base-button',{staticClass:"mt-3",attrs:{"type":"link"},on:{"click":_vm.emitCancelled}},[_vm._v("Annulla")]),_c('base-button',{staticClass:"mt-3",attrs:{"type":"primary"},on:{"click":_vm.emitEdit}},[_vm._v("Ok")])],1)]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }