import DashboardLayout from "@/Layout/DashboardLayout.vue";
import AuthLayout from "@/views/AccountPages/AuthLayout.vue";
import AppointmentTypeDetails from "@/views/AppointmentTypeDetails";
import TimeTable from "@/components/TimeTable/TimeTable";
import Maintenance from "@/views/Maintenance";
//import Book from '@/views/Book.vue';

import NotFound from "@/views/NotFoundPage.vue";

const routes = [
  //Per mettere in manutenzione abilitare questa pagina
  //{ path: "/*", component: Maintenance },
  {
    path: "/",
    redirect: "appointment_types",
    component: DashboardLayout,
    children: [
      // {
      //   path: "/first_configuration_finished",
      //   name: "first_configuration_finished",
      //   meta: { shownName: "", requiresAuth: true },
      //   // route level code-splitting
      //   // this generates a separate chunk (about.[hash].js) for this route
      //   // which is lazy-loaded when the route is visited.
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "demo" */ "../views/FirstConfigurationFinished.vue"
      //     )
      // },
      {
        path: "/team_invitation_management/:teamInvitationId",
        name: "team_invitation_management",
        props: route => ({ teamInvitationId: String(route.params.teamInvitationId) }),
        meta: {
          shownName: "Gestione Invito nel Team",
          requiresAuth: true,
          subTitle:
            "Qui puoi accettare o rifiutare un invito nel team"
        },
        component: () =>
          import(/* webpackChunkName: "demo" */ "../views/Teams/TeamInvitationManagement.vue")
      },
      {
        path: "/team_details/:id",
        name: "team_details",
        props: route => ({ id: String(route.params.id) }),
        meta: {
          shownName: "Gestione Team",
          requiresAuth: true,
          //subTitle:
            //"Qui puoi gestire il tuo team"
        },
        component: () =>
          import(/* webpackChunkName: "demo" */ "../views/Teams/TeamDetails.vue")
      },
      {
        path: "/teams",
        name: "teams",
        meta: {
          shownName: "Teams",
          requiresAuth: true,
          subTitle:
            "Qui puoi gestire i teams a cui appartieni"
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "demo" */ "../views/Teams/TeamsIndex.vue")
      },
      {
        path: "/appointments",
        name: "appointments",
        meta: { shownName: "Appuntamenti Schedulati", requiresAuth: true },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "demo" */ "../views/Appointments.vue"),
        props: route => ({
          intro: Boolean(route.query.intro)
        })
      },
      {
        path: "/how_does_it_work",
        name: "how_does_it_work",
        meta: { shownName: "", requiresAuth: true },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "demo" */ "../views/HowDoesItWork.vue")
      },
      {
        path: "/thank_you",
        name: "thank_you",
        meta: { shownName: "", requiresAuth: true },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "demo" */ "../views/ThankYou.vue")
      },
      {
        path: "/Logo",
        name: "Logo",
        meta: {
          shownName: "Gestione Logo",
          requiresAuth: true,
          subTitle:
            "Qui puoi modificare il logo che verrà mostrato sulla tua pagina di prenotazione"
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "demo" */ "../views/Logo.vue")
      },
      {
        path: "/subscription_payment",
        name: "subscription_payment",
        meta: { shownName: "Pagamento Abbonamento", requiresAuth: true },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "../views/SubscriptionPayment.vue"
          ),
        props: route => ({ planId: Number(route.query.planId) })
      },
      // {
      //   path: '/subscription_payment',
      //   name: 'subscription_payment',
      //   meta: { shownName: 'Pagamento Abbonamento', requiresAuth: true },
      //   // route level code-splitting
      //   // this generates a separate chunk (about.[hash].js) for this route
      //   // which is lazy-loaded when the route is visited.
      //   component: () => import(/* webpackChunkName: "demo" */ '../views/SubscriptionPayment.vue'),
      //   props: route => ({ planId: Number(route.params.planId)})
      // },
      {
        path: "/subscription",
        name: "subscription",
        meta: { shownName: "Abbonamento", requiresAuth: true },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "demo" */ "../views/Subscription.vue")
      },
      {
        path: "/getting_started",
        name: "getting_started",
        meta: { shownName: "Guida Rapida", requiresAuth: true },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "demo" */ "../views/GettingStarted.vue")
      },
      // {
      //   path: "/first_configuration_wizard",
      //   name: "first_configuration_wizard",
      //   meta: { requiresAuth: false },
      //   // route level code-splitting
      //   // this generates a separate chunk (about.[hash].js) for this route
      //   // which is lazy-loaded when the route is visited.
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "demo" */ "../views/FirstConfigurationWizard.vue"
      //     )
      // },
      {
        path: "/billing_info",
        name: "billing_info",
        meta: { shownName: "Informazioni Fatturazione", requiresAuth: true },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "../views/AccountPages/BillingInfo.vue"
          )
      },
      {
        path: "/profile",
        name: "profile",
        meta: { shownName: "Profilo", requiresAuth: true },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "../views/AccountPages/UserProfile/EditProfileForm.vue"
          )
      },
      {
        path: "/group_user",
        name: "group_user",
        meta: { shownName: "Team e Piano", requiresAuth: true },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "../views/AccountPages/GroupUser.vue"
          )
      },
      {
        path: "/appointment_types",
        name: "appointment_types",
        meta: {
          // shownName: "Tipi di Appuntamento",
          shownName: "Pagine di Prenotazione",
          requiresAuth: true,
          // subTitle: "Qui puoi gestire le tue tipologie di appuntamento"
          subTitle: "Qui puoi gestire le tue pagine di prenotazione"
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "demo" */ "../views/AppointmentTypes.vue")
      },
      // {
      //   path: "/appointment_type_details/:id",
      //   name: "appointment_type_details",
      //   meta: {
      //     shownName: "Dettagli Pagina di Prenotazione",
      //     requiresAuth: true,
      //     subTitle:
      //       "Qui puoi modificare i dettagli di questa pagina di prenotazione"
      //   },
      //   component: AppointmentTypeDetails,
      //   props: route => ({ id: Number(route.params.id) })
      // },

      {
        path: "/appointment_type_details/:appTypeDiscriminator/:id",
        name: "appointment_type_details",
        meta: {
          shownName: "Dettagli Pagina di Prenotazione",
          requiresAuth: true,
          subTitle:
            "Qui puoi modificare i dettagli di questa pagina di prenotazione"
        },
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "../views/AppointmentTypeDetails.vue"
          ),
        props: route => ({
          id: Number(route.params.id),
          appTypeDiscriminator: String(route.params.appTypeDiscriminator),
          teamId: route.params.teamId
        })
      },

      {
        path: "/availabilities",
        name: "availabilities",
        meta: {
          shownName: "Elenco Disponibilità",
          requiresAuth: true,
          subTitle:
            "Qui puoi vedere le tue disponibilità, modificarle o crearne di nuove"
        },
        component: () =>
          import(/* webpackChunkName: "demo" */ "../views/Availabilities.vue")
        // children: [
        //   {
        //     path: "new",
        //     component: TimeTable,
        //     props: route => ({ id: 0 }),
        //     //props: route => ({ id: Number(route.params.id) }),
        //     meta: {
        //       shownName: "Gestione Disponibilità",
        //       requiresAuth: true,
        //       subTitle:
        //         "Qui puoi definire gli orari in cui sei disponibile per poi associarli a un tipo di appuntamento"
        //     }
        //   },
        //   {
        //     path: ":id",
        //     name: "availability",
        //     component: TimeTable,
        //     props: route => ({ id: Number(route.params.id) }),
        //     meta: {
        //       shownName: "Gestione Disponibilità",
        //       requiresAuth: true,
        //       subTitle:
        //         "Qui puoi definire gli orari in cui sei disponibile per poi associarli a un tipo di appuntamento"
        //     }
        //   }
        // ]
      },
      {
        path: "/availabilities/:id",
        name: "availability",
        props: route => ({ id: Number(route.params.id) }),
        meta: {
          shownName: "Gestione Disponibilità",
          requiresAuth: true,
          subTitle:
            "Qui puoi definire gli orari in cui sei disponibile per poi associarli a un tipo di appuntamento"
        },
        component: () =>
          import(/* webpackChunkName: "demo" */ "../views/Availability.vue")
      },

      {
        path: "/calendars",
        name: "calendars",
        meta: {
          shownName: "Calendari Sincronizzati",
          requiresAuth: true,
          subTitle:
            "Qui puoi collegare i calendari da sincronizzare con Schedeo.<br/>Chi prenota non vedrà il contenuto dei tuoi calendari."
        },
        component: () =>
          import(/* webpackChunkName: "demo" */ "../views/Calendars.vue"),
        //props: true,
        props: route => ({
          id: Number(route.params.id),
          intro: Boolean(route.query.intro),
          code: route.query.code,
          state: route.query.state
        })
      }
    ]
  },
  {
    path: "/book/:ownerUrl",
    name: "main_booking_page",
    component: () =>
      import(/* webpackChunkName: "demo" */ "../views/MainBookingPage.vue"),
    props: route => ({
      ownerUrl: String(route.params.ownerUrl)
    })
  },
  {
    path: "/cancel/:eventId",
    name: "cancel",
    meta: { shownName: "Cancellazione" },
    component: () =>
      import(/* webpackChunkName: "demo" */ "../views/Cancel.vue"),
    //props: true,
    props: route => ({
      eventId: String(route.params.eventId)
    })
  },
  {
    path: "/reschedule/:eventId",
    name: "reschedule",
    meta: { shownName: "Rischedulazione" },
    component: () =>
      import(/* webpackChunkName: "demo" */ "../views/Reschedule.vue"),
    //props: true,
    props: route => ({
      eventId: String(route.params.eventId)
    })
  },
  {
    path: "/book/:ownerUrl/:appUrl",
    name: "book",
    meta: { shownName: "Prenotazione" },
    // component: Book,
    component: () => import(/* webpackChunkName: "demo" */ "../views/Book.vue"),
    //props: true,
    props: route => ({
      ownerUrl: String(route.params.ownerUrl),
      appUrl: String(route.params.appUrl),
      intro: Boolean(route.query.intro)
    })
  },
  {
    path: "/why_schedeo",
    name: "why_schedeo",
    meta: { shownName: "Perchè Schedeo" },
    component: () =>
      import(/* webpackChunkName: "demo" */ "../views/WhySchedeo.vue")
  },

  {
    path: "/",
    redirect: "login",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "login",
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "../views/AccountPages/Login.vue"
          )
      },
      {
        path: "/register",
        name: "register",
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "../views/AccountPages/Register.vue"
          )
      },
      {
        // path: '/reset-password-request',
        // name: 'resetPasswordRequest',
        path: "/reset_password_request",
        name: "reset_password_request",
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "../views/AccountPages/ResetPasswordRequest.vue"
          )
      },
      {
        // path: '/reset-password',
        // name: 'resetPassword',
        path: "/reset_password",
        name: "reset_password",
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "../views/AccountPages/ResetPassword.vue"
          )
      },
      {
        path: "/not-found",
        name: "not-found",
        //component: () => import(/* webpackChunkName: "demo" */ "../views/NotFoundPage.vue")
        component: NotFound
      },
      { path: "*", component: NotFound }
    ]
  }
];

export default routes;
