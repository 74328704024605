


















import Vue, { PropType } from "vue";
import { getWeekDays } from "@/util/dateManager";
import { Prop } from "vue/types/options";
import { BIconSortNumericDown } from "bootstrap-vue";
import { TimeInterval } from "@/data/Models/TimeInterval";
export default Vue.extend({
  name: "week-day-selector",
  data() {
    return {
      weekDays: Array<{
        dowId: number;
        dayName: string;
        isSelected: boolean;
        isDisabled: boolean;
      }>()
    };
  },
  props: {
    selectedWeekDay: {
      type: Number,
      default: -1
    }
  },
  mounted() {
    this.LoadUI();
  },
  methods: {
    LoadUI() {
      let tempWeekDays = getWeekDays();
      this.weekDays.length = 0; //blanko array
      let selectedWeekDay = this.selectedWeekDay;

      tempWeekDays.forEach(d => {
        let isSelected = selectedWeekDay === d.dowId; //necessario perchè this non è il vue component
        this.weekDays.push({
          dowId: d.dowId,
          dayName: d.dayName,
          isSelected: isSelected,
          isDisabled: isSelected
        });
      });
    },
    emitDaysSelected() {
      //Restituisco i giorni selezionati
      this.$emit(
        "week-day-selector-days-selected",
        this.weekDays
          .filter(d => d.isSelected)
          .map(d => {
            return d.dowId;
          })
      );
    }
  },
  watch: {
    selectedWeekDay() {
      this.LoadUI();
    }
  }
});
