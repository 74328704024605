<template>
  <!-- :ok-only="globalAlertInfo.okOnly" -->
  <modal
    :show="globalAlertInfo.visible"
    v-on:close="hideGlobalAlert()"
    :gradient="globalAlertInfo.modalGradient"
    :modal-classes="globalAlertInfo.modalClasses"
    :modalContentClasses="globalAlertInfo.contentClasses"
    :headerClasses="globalAlertInfo.headerClasses"
  >
    <template v-slot:header>
        <h5 v-if="globalAlertInfo.title != ''" class="modal-title">{{ globalAlertInfo.title }}</h5>
    </template>
    <template v-slot:close-button>
      <div></div>
    </template>
    <!--Layout Errore-->
    <!--text-center-->
    <!-- class="mt-3 py-3" -->
    <div >
      <i
        v-if="globalAlertInfo.iconClasses"
        class="pb-3"
        :class="globalAlertInfo.iconClasses"
      ></i>
      <p>
        <!-- v-html="globalAlertInfo.message" -->
        {{ globalAlertInfo.message }}
      </p>
    </div>

    <template v-slot:footer>
      <base-button
        :type="globalAlertInfo.buttonType"
        @click="hideGlobalAlert()"
        :disabled="globalAlertInfo.preventClosingSeconds > 0"
        >Ok
        <span v-if="globalAlertInfo.preventClosingSeconds > 0"
          >({{ globalAlertInfo.preventClosingSeconds }})</span
        >
        <div v-if="globalAlertInfo.preventClosingSeconds > 0">
          per favore leggi prima di chiudere
        </div></base-button
      >
    </template>
  </modal>
</template>
<script>
export default {
  name: "modal-alert",
  data() {
    return {
      // asd: false
    };
  },
  props: {
    showProp: { type: Boolean, default: false },
    showClose: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    message() {
      //Ogni volta che mostro un messaggio diverso dal precedente
      if (
        this.globalAlertInfo.preventClosingSeconds != undefined &&
        this.globalAlertInfo.preventClosingSeconds > 0
      ) {
        let preventClosingTimeout = setInterval(
          //Gli passo self altrimenti il setInterval perde il contesto del componente.
          function(self) {
            //Copio tutto in una variabile locale e la modifico
            let globalAlertInfoCopy = { ...self.globalAlertInfo };
            globalAlertInfoCopy.preventClosingSeconds--;

            self.$store.dispatch("updateGlobalAlertInfo", globalAlertInfoCopy);

            if (globalAlertInfoCopy.preventClosingSeconds <= 0) {
              clearInterval(preventClosingTimeout);
            }
          },
          1000,
          this
        );
      }
    }
  },
  computed: {
    /**
     * @returns {object}
     */
    globalAlertInfo() {
      return this.$store.state.globalAlertInfo;
    },
    /**
     * @returns {object}
     */
    message() {
      return this.$store.state.globalAlertInfo.message;
    }
  },
  methods: {
    hideGlobalAlert() {
      this.$hideModal();
    }
  }
};
</script>
